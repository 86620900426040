/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react';
import cn from 'classnames';

import { COPYRIGHT_TEXT, LINKS } from '@/appConstants';
import {
  Container,
  DeveloperLogo,
  DownloadAppLink,
  DownloadAppLinks,
  Logo,
  SocialLink,
  SocialLinks,
} from '@/components';

import styles from './Footer.module.scss';

export type Props = {
  className?: string;
};

export const Footer: FC<Props> = ({ className }) => {
  return (
    <footer className={cn(styles.footer, className)}>
      <Container className={styles.container}>
        <div className={styles.up}>
          <Logo className={styles.logo} color="primary" />
          <span className={cn(styles.live, 'notranslate')}>Talents.live</span>
          <p className={styles.description}>
            is our free of charge socialmedia app for creative talents, fans such as talent-scouts
            and agencies
          </p>
          <DownloadAppLinks className={styles.downloadAppLinks}>
            {(links) =>
              links.map((link) => (
                <DownloadAppLink
                  key={link.label}
                  className={styles.downloadAppLink}
                  to={link.to}
                  label={link.label}
                  imgPath={link.imgPath}
                />
              ))
            }
          </DownloadAppLinks>
        </div>
        <div className={styles.bottom}>
          <div className={cn(styles.externalLinks, 'notranslate')}>
            {LINKS.map(({ label, to }) => (
              <a
                key={label}
                className={styles.externalLink}
                href={to}
                target="_blank"
                rel="noreferrer"
              >
                {label}
              </a>
            ))}
          </div>
          <SocialLinks className={styles.socialLinks}>
            {(links) =>
              links.map((link) => (
                <SocialLink
                  key={link.icon}
                  className={styles.socialLink}
                  to={link.to}
                  icon={link.icon}
                />
              ))
            }
          </SocialLinks>
          <DeveloperLogo className={styles.developer} />
          <p className={styles.copyright}>{COPYRIGHT_TEXT}</p>
        </div>
      </Container>
    </footer>
  );
};
