/* eslint-disable max-len */
import { FC } from 'react';
import cn from 'classnames';

import { Props } from '.';

export const Logo: FC<Props> = ({ className, color = 'white' }) => {
  return (
    <svg
      className={cn(className)}
      width="115"
      height="143"
      viewBox="0 0 115 143"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m57.5.377 18.999 39.814h-10.39L57.5 22.103l-8.61 18.088H38.5L57.5.377ZM42.111 37.921h5.33L57.5 16.794 67.556 37.92h5.331L57.499 5.676 42.112 37.92Z"
        fill={color === 'white' ? 'white' : 'url(#logo-gradient-a)'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m.305 45.489.457-2.175h113.477l.457 2.175-44.613 19.355-11.452 77.564H56.37L44.918 64.844.305 45.49Zm5.927.094L46.994 63.27 57.5 134.423l10.506-71.154 40.762-17.686H6.232Z"
        fill={color === 'white' ? 'white' : 'url(#logo-gradient-b)'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m30.793 52.12.426-2.189h52.563l.43 2.186-19.992 8.075-5.582 57.65-2.275-.004-5.34-57.644-20.23-8.075Zm6.334.081 16.043 6.404 4.356 47.017 4.553-47.015 15.86-6.406H37.127Z"
        fill={color === 'white' ? 'white' : 'url(#logo-gradient-c)'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m28.442 61.359 8.483 3.58-3.138 6.758 7.37-5.845 1.355 9.47-29.248 23.2-1.746-1.37L28.442 61.36Zm1.132 2.944L15.67 93.709l24.396-19.35-.609-4.255-8.017 6.359-1.75-1.362 4.174-8.987-4.29-1.811Z"
        fill={color === 'white' ? 'white' : 'url(#logo-gradient-d)'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m86.805 61.359 16.924 35.793-1.747 1.37-29.248-23.199 1.356-9.471 7.37 5.845-3.139-6.757 8.484-3.581Zm-5.422 4.755 4.173 8.987-1.75 1.362-8.017-6.359-.609 4.255 24.397 19.35-13.904-29.406-4.29 1.811Z"
        fill={color === 'white' ? 'white' : 'url(#logo-gradient-e)'}
      />
      <defs>
        <linearGradient
          id="logo-gradient-a"
          x1="98.518"
          y1="-18.542"
          x2="20.153"
          y2="101.709"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF003D" />
          <stop offset="1" stopColor="#FFA438" />
        </linearGradient>
        <linearGradient
          id="logo-gradient-b"
          x1="98.519"
          y1="-18.542"
          x2="20.154"
          y2="101.709"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF003D" />
          <stop offset="1" stopColor="#FFA438" />
        </linearGradient>
        <linearGradient
          id="logo-gradient-c"
          x1="98.519"
          y1="-18.542"
          x2="20.154"
          y2="101.709"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF003D" />
          <stop offset="1" stopColor="#FFA438" />
        </linearGradient>
        <linearGradient
          id="logo-gradient-d"
          x1="98.519"
          y1="-18.543"
          x2="20.154"
          y2="101.709"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF003D" />
          <stop offset="1" stopColor="#FFA438" />
        </linearGradient>
        <linearGradient
          id="logo-gradient-e"
          x1="98.519"
          y1="-18.542"
          x2="20.154"
          y2="101.709"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF003D" />
          <stop offset="1" stopColor="#FFA438" />
        </linearGradient>
      </defs>
    </svg>
  );
};
