/* eslint-disable no-param-reassign */
import { toast } from 'react-toastify';
import { flow, getRoot, types } from 'mobx-state-tree';

import { STAGES } from '@/appConstants';
import { TLTMCrowdsale } from '@/services';
import { FetchStatus, RootInstance } from '@/types';
import { clog, getBalanceAmount, toBigNumber } from '@/utils';

import { rootStore } from '..';

const Stage = types.model({
  limit: types.string,
  endTimestamp: types.number,
  soldAmount: types.maybe(types.string),
});

export const Crowdsale = types
  .model({
    fetchStatus: types.enumeration<FetchStatus>('fetchStatus', Object.values(FetchStatus)),
    tokenPrice: types.number,

    stages: types.array(Stage),
    currentStage: types.number,
    startTime: types.number,

    softcap: types.string,
    hardcap: types.string,
    totalSold: types.string,

    paymentMethods: types.map(types.number),
    amounts: types.array(types.string),
  })
  .actions((self) => {
    const fetchStagesData = flow(function* fetchStagesData() {
      self.stages.clear();
      try {
        for (let i = 0; i < STAGES; i += 1) {
          const [limit, endTimestamp] = yield Promise.all([
            TLTMCrowdsale.getStageLimits(i),
            TLTMCrowdsale.getStageEndTimestamp(i),
          ]);
          self.stages.push({
            limit,
            endTimestamp: +endTimestamp,
          });
        }
      } catch (err) {
        clog('[Crowdsale]: fetchStagesData', err);
      }
    });

    const fetchCurrentStageIndex = flow(function* fetchCurrentStageIndex() {
      try {
        const value = yield TLTMCrowdsale.getCurrentStageIndex();
        self.currentStage = +value;
      } catch (err) {
        clog('[Crowdsale]: fetchCurrentStageIndex', err);
      }
    });

    const fetchStartTime = flow(function* fetchStartTime() {
      try {
        const value = yield TLTMCrowdsale.startTime();
        self.startTime = +value;
      } catch (err) {
        clog('[Crowdsale]: fetchStartTime', err);
      }
    });

    const fetchTotalSold = flow(function* fetchTotalSold() {
      try {
        const value = yield TLTMCrowdsale.getTotalSold();
        self.totalSold = value;
      } catch (err) {
        clog('[Crowdsale]: fetchTotalSold', err);
      }
    });

    const fetchSoftcap = flow(function* fetchSoftcap() {
      try {
        const softcap = yield TLTMCrowdsale.getSoftcap();
        self.softcap = softcap;
      } catch (err) {
        clog('[Crowdsale]: fetchSoftcap', err);
      }
    });

    const fetchCurrentStageSoldAmount = flow(function* fetchCurrentStageSoldAmount() {
      if (self.currentStage === -1) {
        yield fetchCurrentStageIndex();
      }
      if (self.currentStage === -1 || self.currentStage === STAGES) {
        return; // ALREADY_FINISHED or NOT_YET_STARTED
      }
      try {
        const soldAmount = yield TLTMCrowdsale.amountsSold(self.currentStage);
        clog('soldAmount', soldAmount, self.currentStage, self.stages[self.currentStage]);
        self.stages[self.currentStage].soldAmount = soldAmount;
      } catch (err) {
        clog('[Crowdsale]: fetchCurrentStageSoldAmount', err);
      }
    });

    const fetchPaymentMethodsIndexes = flow(function* fetchPaymentMethodsIndexes(
      paymentMethodsCount: number,
    ) {
      const promises = new Array(paymentMethodsCount).fill(0).map((_, index) => {
        return TLTMCrowdsale.getPaymentMethod(index.toString());
      });

      try {
        const addressesByIndexes: string[] = yield Promise.all(promises);
        addressesByIndexes.forEach((address, index) => {
          self.paymentMethods.set(address, index);
        });
      } catch (err) {
        clog('', err);
      }
    });

    const setAmounts = (amounts: string[]) => {
      self.amounts.clear();
      amounts.forEach((amount) => {
        self.amounts.push(amount);
      });
    };

    const fetchUserTokensAmounts = flow(function* fetchUserTokensAmounts() {
      if (self.paymentMethods.size === 0) return;
      const store = getRoot<RootInstance>(self);
      const promises: Promise<string>[] = [];
      store.tokenPrices.data.forEach(({ address }) => {
        const id = self.paymentMethods.get(address) ?? -1;
        promises.push(TLTMCrowdsale.amounts(store.user.address, id));
      });

      try {
        const amountsSettled: PromiseSettledResult<string>[] = yield Promise.allSettled(promises);
        const amounts = amountsSettled.map((res) => {
          switch (res.status) {
            case 'rejected': {
              return '0';
            }
            case 'fulfilled':
            default: {
              return res.value;
            }
          }
        });
        setAmounts(amounts);
      } catch (err) {
        clog('', err);
      }
    });

    const fetchTokenPrice = flow(function* fetchTokenPrice() {
      try {
        const price: string = yield TLTMCrowdsale.getCurrentStageTokenPrice();
        self.tokenPrice = +price / 10 ** 9;
      } catch (err) {
        clog('[Crowdsale]: fetchTokenPrice', err);
      }
    });

    const fetchCrowdsaleData = flow(function* fetchCrowdsaleData() {
      self.fetchStatus = FetchStatus.loading;
      if (!getRoot<RootInstance>(self).user.isContractsExists) yield Promise.resolve(undefined);
      try {
        const promises = [
          fetchStartTime(),
          fetchCurrentStageIndex(),
          fetchTokenPrice(),
          fetchSoftcap(),
          fetchTotalSold(),
          fetchStagesData(),
        ];
        yield Promise.all(promises);
        self.fetchStatus = FetchStatus.success;
      } catch (err) {
        clog('[Crowdsale.store/fetchCrowdsaleData]: ', err);
        toast.error(`Error fetching Crowdsale data`);
        self.fetchStatus = FetchStatus.error;
      }
    });

    return {
      fetchSoftcap,
      fetchTotalSold,

      fetchStartTime,
      fetchCurrentStageIndex,
      fetchStagesData,

      fetchUserTokensAmounts,
      fetchPaymentMethodsIndexes,
      fetchCurrentStageSoldAmount,

      fetchCrowdsaleData,
      fetchTokenPrice,
    };
  })
  .views((self) => ({
    getCrowdsaleTokenIndex() {
      if (!self.paymentMethods.size) return { crowdsaleTokenIndex: undefined };
      const [foundCrowdsaleTokenEntries] = Object.entries(self.paymentMethods.toJSON()).filter(
        ([, value]) => value === 0,
      );
      const [crowdsaleTokenAddress] = foundCrowdsaleTokenEntries;
      const crowdsaleTokenIndex = getRoot<RootInstance>(self).tokenPrices.data.findIndex(
        ({ address }) => crowdsaleTokenAddress === address,
      );

      return {
        crowdsaleTokenIndex,
      };
    },
    get currentStageSoldAmount() {
      return self.stages[self.currentStage]?.soldAmount;
    },
    get currentStageLimit() {
      return toBigNumber(self.stages[self.currentStage].limit)
        .multipliedBy(10 ** 6)
        .toFixed();
    },
    get hasUserLockedCrowdsaleAmounts() {
      const { crowdsaleTokenIndex } = this.getCrowdsaleTokenIndex();
      return crowdsaleTokenIndex !== undefined
        ? Number(self.amounts.toJSON()[crowdsaleTokenIndex]) > 0
        : false;
    },
    get isCrowdsaleIsOver() {
      if (self.stages.length !== STAGES) return false;
      return self.stages[STAGES - 1].endTimestamp < Math.floor(Date.now() / 1e3);
    },
    get isSoftcapCollected() {
      return toBigNumber(this.getTotalSold).isGreaterThanOrEqualTo(this.getSoftcap);
    },
    get isAlreadyStarted() {
      return self.startTime !== 0;
    },
    get getSoftcap() {
      return getBalanceAmount(self.softcap, rootStore.crowdsaleToken.decimals);
    },
    get getTotalSold() {
      return getBalanceAmount(self.totalSold, rootStore.crowdsaleToken.decimals);
    },
    get getHardcap() {
      return self.stages
        .reduce((acc, { limit }) => {
          return acc.plus(limit);
        }, toBigNumber('0'))
        .multipliedBy(10 ** 6)
        .toFixed();
    },
    get isLoading() {
      return self.fetchStatus === FetchStatus.loading;
    },
    get isLoadingError() {
      return self.fetchStatus === FetchStatus.error;
    },
    get isLoadingSuccess() {
      return self.fetchStatus === FetchStatus.success;
    },
  }));
