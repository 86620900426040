/* eslint-disable no-param-reassign */
import { types } from 'mobx-state-tree';

import { getBalanceAmount } from '@/utils';

export const BigNumberValue = types
  .model({
    raw: types.string,
    decimals: types.number,
  })
  .actions((self) => {
    const set = (amount: string, decimals: number) => {
      self.raw = amount;
      self.decimals = decimals;
    };
    return {
      set,
    };
  })
  .views((self) => ({
    get parsed() {
      return getBalanceAmount(self.raw, self.decimals);
    },
  }));
