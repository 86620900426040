import { PositionY } from '.';

const DEFAULT_NOT_CONTAIN_OFFSET = 10;

export function getXPosition(
  parentElement: HTMLElement,
  tooltip: HTMLDivElement,
  offset: number,
): number {
  const { left, right } = parentElement.getBoundingClientRect();
  const windowWidth =
    window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

  const defaultValue = left + parentElement.offsetWidth / 2 - tooltip.offsetWidth / 2 + offset;

  switch (true) {
    case left + parentElement.offsetWidth < tooltip.offsetWidth + offset: {
      return DEFAULT_NOT_CONTAIN_OFFSET;
    }

    // not contain from right
    case windowWidth - right < tooltip.offsetWidth + offset: {
      return right - tooltip.offsetWidth - offset;
    }

    default:
      return defaultValue;
  }
}

export function getYPosition(
  parentElement: HTMLElement,
  tooltip: HTMLDivElement,
  position: PositionY,
  offset: number,
) {
  const { top } = parentElement.getBoundingClientRect();
  const windowHeight =
    window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

  let _position: PositionY = 'top';

  if (position === 'top') {
    _position = top > tooltip.offsetHeight + offset ? position : 'bottom';
  }

  if (position === 'bottom') {
    const bottomOffset = windowHeight - (top + parentElement.offsetHeight);
    _position = bottomOffset > tooltip.offsetHeight ? position : 'top';
  }

  if (_position === 'top') {
    return top - tooltip.offsetHeight - offset;
  }

  return top + parentElement.offsetHeight + offset;
}
