import { FC } from 'react';
import cn from 'classnames';

import { Props } from '.';

import styles from './styles.module.scss';

export const Loader: FC<Props> = ({ className }) => (
  <div className={cn(styles.loader, className)} />
);
