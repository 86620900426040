import React, { createContext, useContext } from 'react';
import { toast } from 'react-toastify';
import { IError, IEvent, IEventError } from '@amfi/connect-wallet/dist/interface';

import { rootStore } from '@/store';
import localStorageWrapper from '@/store/localStorageWrapper';
import { ChainsEnum, IConnectorContextProps, ProvidersEnum } from '@/types';
import { clog, clogError } from '@/utils';

import { walletService } from '..';

import { isWalletConnectError } from './helpers';

declare global {
  interface Window {
    ethereum: any;
  }
}

const log = (...content: unknown[]) => clog('services/WalletConnect[debug]:', ...content);

const WalletConnectorContext = createContext<IConnectorContextProps>({
  connect: (): void => {},
  disconnect: (): void => {},
});

class WalletConnectProvider extends React.Component {
  constructor(props: any) {
    super(props);

    this.connect = this.connect.bind(this);
    this.disconnect = this.disconnect.bind(this);
  }

  componentDidMount() {
    if (window.ethereum) {
      const chainName = localStorageWrapper.getItem('chainName');
      const providerName = localStorageWrapper.getItem('providerName');
      if (this.hasConnectedEarlier) {
        this.connect(chainName as ChainsEnum, providerName as ProvidersEnum);
      }
    }
  }

  get hasConnectedEarlier() {
    return (
      Boolean(localStorageWrapper.getItem('chainName')) &&
      Boolean(localStorageWrapper.getItem('providerName'))
    );
  }

  connect = async (
    chainName: ChainsEnum = ChainsEnum.BinanceSmartChain,
    providerName: ProvidersEnum = ProvidersEnum.MetaMask,
  ) => {
    let isConnected = false;
    try {
      isConnected = await walletService.initWalletConnect(chainName, providerName);
    } catch (err) {
      clogError('connect: provider.initWalletConnect', err);
      this.disconnect();
    }

    if (!isConnected) {
      log('initWalletConnect: isConnected is', isConnected);
      this.disconnect();
      return;
    }

    try {
      const userAccount = await walletService.getAccount();
      if (isWalletConnectError(userAccount)) {
        const msg = userAccount.message?.text;
        throw new Error(msg);
      }
      if (rootStore.user.address && userAccount.address !== rootStore.user.address) {
        this.disconnect();
      } else {
        walletService.setAccountAddress(userAccount.address);
        rootStore.user.setAddress(userAccount.address);
        rootStore.user.setNetwork(chainName);
        rootStore.user.setProvider(providerName);
        // persist values
        localStorageWrapper.setItem('chainName', chainName);
        localStorageWrapper.setItem('providerName', providerName);
      }
    } catch (err) {
      clogError('[connect: provider.getAccount]:', err);
      // there can be:
      // 1. err.message.message
      // 2. err.message
      // 3. err.message.text
      let errorMessage;
      if (typeof (err as Error).message === 'string') {
        errorMessage = (err as Error).message;
      } else if ((err as IError).message?.text) {
        errorMessage = (err as IError).message?.text;
      } else {
        // eslint-disable-next-line max-len
        // @see https://github.com/amaryfilo/connect-wallet/blob/706feb90d3fcaae7356777d5f3ff9fe152975190/src/metamask/index.ts#L139
        errorMessage = (err as { message: { message: string } }).message.message;
      }
      toast.error(errorMessage);
      this.disconnect();
    }

    const eventSubs = walletService.connectWallet.eventSubscriber().subscribe(
      (res: IEvent) => {
        if (res.name === 'accountsChanged' && rootStore.user.address !== res.address) {
          eventSubs.unsubscribe();
          if (rootStore.user.provider !== '') {
            this.connect(rootStore.user.network, rootStore.user.provider);
          } else {
            this.disconnect();
          }
        }
      },
      (err: IEventError) => {
        clogError(err);
        eventSubs.unsubscribe();
        this.disconnect();
      },
    );
  };

  disconnect() {
    log('Disconnected');
    if (this.hasConnectedEarlier) {
      toast.error('Closed connection with wallet');
    }

    delete localStorage.walletconnect; // this is set by default behaviour of WalletConnect provider
    localStorageWrapper.removeItem('providerName');
    rootStore.user.disconnect();
  }

  render() {
    const { children } = this.props;
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    const state = {
      connect: this.connect,
      disconnect: this.disconnect,
    };
    return (
      <WalletConnectorContext.Provider value={state}>{children}</WalletConnectorContext.Provider>
    );
  }
}

export default WalletConnectProvider;

export function useWalletConnectorContext() {
  return useContext(WalletConnectorContext);
}
