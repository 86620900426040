import { INetwork, IProvider, ISettings } from '@amfi/connect-wallet/dist/interface';
import { AbiItem } from 'web3-utils';

export enum ChainsEnum {
  BinanceSmartChain = 'Binance-Smart-Chain',
}

export enum ChainsIdEnum {
  'BinanceSmartChainMainnet' = 56,
  'BinanceSmartChainTestnet' = 97,
}

export enum ProvidersEnum {
  MetaMask = 'MetaMask',
  WalletConnect = 'WalletConnect',
}

export enum ContractsWithDataEnum {
  TLTM = 'TLTM',
  TLTMCrowdsale = 'TLTMCrowdsale',
}

export enum ContractsEnum {
  bep20 = 'bep20',
}
export interface IConnectorContextProps {
  connect: (chainName: ChainsEnum, providerName: ProvidersEnum) => void;
  disconnect: () => void;
}

export interface IConnectWallet {
  network: INetwork;
  provider: {
    [key in ProvidersEnum]: IProvider;
  };
  settings: ISettings;
}

interface IExtendedProvider extends Omit<IProvider, 'name'> {
  img: string;
  name: ProvidersEnum;
}
export type IProviders = {
  [key in ProvidersEnum]: IExtendedProvider;
};

export type IChain = {
  [key in ChainsEnum]: {
    name: ChainsEnum;
    network: INetwork;
    provider: IProviders;
    img: string;
    explorer: string;
  };
};

type TAddressWithChains = {
  [key in ChainsEnum]: string;
};

export interface IContractParams {
  address: TAddressWithChains;
  abi: Array<AbiItem>;
}

export interface IContracts {
  decimals: number;
  names: string[];
  type: string;
  params: {
    [key in ContractsEnum | ContractsWithDataEnum]: IContractParams;
  };
}
