import { FC } from 'react';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';

import { Props, Tab } from '.';

import styles from './BuyFormTabs.module.scss';

const TABS: Tab[] = [
  {
    to: 'crypto',
    title: 'Buy Crypto',
    subtitle: 'With BNB, USDT, BUSD, WETH',
  },
  {
    to: 'fiat',
    title: 'With Fiat',
  },
];

export const BuyFormTabs: FC<Props> = observer(({ className, value, onChange }) => {
  return (
    <div className={cn(className)}>
      <ul className={styles.list}>
        {TABS.map((tab) => (
          <li
            key={tab.to}
            className={cn(styles.tab, {
              [styles.active]: tab.to === value,
            })}
          >
            <button type="button" className={styles.btn} onClick={() => onChange(tab.to)}>
              <span className={styles.title}>{tab.title}</span>
              <span
                className={cn(styles.subtitle, {
                  [styles.hidden]: !tab.subtitle,
                })}
              >
                {tab.subtitle}
              </span>
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
});
