import { FC } from 'react';
import {
  components,
  ControlProps,
  DropdownIndicatorProps,
  IndicatorsContainerProps,
  MenuProps,
  OptionProps,
  SingleValueProps,
  ValueContainerProps,
} from 'react-select';

import { Icon } from '@/components';
import { TCurrency } from '@/types';

import { ICON_MAPPER, Option } from '.';

import styles from './CurrencySelect.module.scss';

export const CurrencyIcon: FC<{ value: TCurrency }> = ({ value }) => {
  return (
    <span className={styles.currencyIcon}>
      <Icon name={ICON_MAPPER[value]} />
    </span>
  );
};

export const Menu: FC<MenuProps<Option, false>> = (props) => {
  return <components.Menu {...props} className={styles.menu} />;
};

export const Control: FC<ControlProps<Option, false>> = (props) => {
  return <components.Control {...props} className={styles.control} />;
};

export const ValueContainer: FC<ValueContainerProps<Option, false>> = (props) => {
  return <components.ValueContainer {...props} className={styles.valueContainer} />;
};

export const SingleValue: FC<SingleValueProps<Option, false>> = ({ children, data, ...props }) => {
  return (
    <components.SingleValue {...props} data={data} className={styles.singleValue}>
      <CurrencyIcon value={data.value} />
      {children}
    </components.SingleValue>
  );
};

export const OptionComponent: FC<OptionProps<Option, false>> = ({ children, data, ...props }) => {
  return (
    <components.Option {...props} data={data} className={styles.option}>
      <CurrencyIcon value={data.value} />
      {children}
    </components.Option>
  );
};

export const IndicatorsContainer: FC<IndicatorsContainerProps<Option, false>> = (props) => {
  return <components.IndicatorsContainer {...props} className={styles.indicatorsContainer} />;
};

export const DropdownIndicator: FC<DropdownIndicatorProps<Option, false>> = (props) => {
  return (
    <components.DropdownIndicator {...props} className={styles.dropdownIndicator}>
      <Icon name="chevron" />
    </components.DropdownIndicator>
  );
};
