import { toBigNumber } from '.';

export const numberFormat = {
  getDigitsGrouped(value: string, digitsInGroup = 3, divider = ' ') {
    return value
      .split('')
      .reduce((acc: string[], curr, index, arr) => {
        acc.push(curr);
        const currentIndex = arr.length - index - 1;
        if (currentIndex % digitsInGroup === 0 && currentIndex !== 0) acc.push(divider);
        return acc;
      }, [])
      .join('');
  },
};

export const numberFormatter = (
  value: number | string,
  useGrouping = true,
  displayDecimals = 4,
): string => {
  let ret = toBigNumber(value).decimalPlaces(displayDecimals).toFixed();
  if (useGrouping) {
    let [integerPart, decimalsPart = ''] = ret.split('.');
    integerPart = numberFormat.getDigitsGrouped(integerPart);
    decimalsPart = decimalsPart ? `.${decimalsPart}` : decimalsPart;
    ret = `${integerPart}${decimalsPart}`;
  }

  return ret;
};
