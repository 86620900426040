import { FC } from 'react';

import { TALENTUM_TOKEN } from '@/appConstants';
import { ButtonForwardedRef, Tooltip } from '@/components';
import { HeaderConnectionMenu } from '@/containers/Header';

import styles from './BuyFormConnectWalletToast.module.scss';

export const BuyFormConnectWalletToast: FC = () => {
  return (
    <div className={styles.toast}>
      <p className={styles.toastContent}>
        If you want to buy <span className="notranslate">{TALENTUM_TOKEN}</span> token please
        connect your wallet, otherwise you can buy crypto with you credit card
      </p>

      <Tooltip event="click" content={<HeaderConnectionMenu />} positionY="bottom">
        <ButtonForwardedRef className={styles.connectBtn} type="button">
          Connect wallet to buy <span className="notranslate">{TALENTUM_TOKEN}</span>
        </ButtonForwardedRef>
      </Tooltip>
    </div>
  );
};
