import { IS_PRODUCTION } from '@/config';
import { IFeature } from '@/containers/Features';
import { ExternalLink, Link, TLanguageCode } from '@/types';

export const BASE_URL = IS_PRODUCTION ? 'talentum.capital' : 'devtalents.rocknblock.io';

export const TALENTUM_TOKEN = 'TALENTUM';

export const COPYRIGHT_TEXT = `${TALENTUM_TOKEN} © ${new Date().getFullYear()} All Rights Reserved.`;

export const BUY_FORM_NODE_ID = 'buy-form';

export const features: IFeature[] = [
  {
    percent: 5,
    name: 'Reflection to the holders',
  },
  {
    percent: 1,
    name: 'Financing scholarships',
  },
  {
    percent: 2,
    name: 'Liquidity Pool',
  },
  {
    percent: 2,
    name: 'Platform Development',
  },
];

export const LINKS: ExternalLink[] = [
  {
    label: 'Whitepaper',
    to: `https://${BASE_URL}/whitepaper/`,
  },
  {
    label: 'Github',
    to: '#',
  },
];

export const SOCIAL_LINKS: Link[] = [
  {
    to: 'https://vm.tiktok.com/ZMLNEvfsw/',
    icon: 'tiktok',
  },
  {
    to: 'https://www.instagram.com/talentum_token_official/',
    icon: 'instagram',
  },
  {
    to: 'https://t.me/+yByk4_yDMS5iMGQy',
    icon: 'telegram',
  },
  {
    to: 'https://twitter.com/TalentumO',
    icon: 'twitter',
  },
  {
    to: 'https://discord.gg/ZYH5WQeU',
    icon: 'discord',
  },
  {
    to: 'https://www.reddit.com/r/TalentumTokenOfficial/',
    icon: 'reddit',
  },
  {
    to: '#',
    icon: 'mail',
  },
];

export const DEVELOPER_LINK = 'https://rocknblock.io';

export const ONRAMPER_API_KEY = 'pk_test_ass3gtLSWQpI11IWUZLJdrfyQhj7bTw_3xwLvhEvH6Q0';

const ONE_DAY_AS_SECONDS = 24 * 60 * 60;
const SEVEN_DAYS_AS_SECONDS = 7 * ONE_DAY_AS_SECONDS;
const FOUR_WEEKS_AS_SECONDS = 4 * SEVEN_DAYS_AS_SECONDS;
const STAGE_DURATION_AS_SECONDS = FOUR_WEEKS_AS_SECONDS;
export const STAGE_DURATION_AS_DAYS = STAGE_DURATION_AS_SECONDS / ONE_DAY_AS_SECONDS;
export const STAGE_DURATION_AS_WEEKS = STAGE_DURATION_AS_SECONDS / SEVEN_DAYS_AS_SECONDS;
export const STAGES_PRICES = ['50000000', '90000000', '130000000', '150000000'];
export const STAGES = STAGES_PRICES.length;

export * from './bigNumbers';

export const DEFAULT_LANGUAGE: TLanguageCode =
  (localStorage.getItem('language') as TLanguageCode) || 'en';
