import { FC, PropsWithChildren } from 'react';
import cn from 'classnames';

import styles from './styles.module.scss';

export type Props = PropsWithChildren<{
  className?: string;
}>;

export const Title: FC<Props> = ({ children, className }) => {
  return <h3 className={cn(styles.title, className)}>{children}</h3>;
};

export const SimpleTitle: FC<Props> = ({ children, className }) => {
  return <h3 className={cn(styles.simpleTitle, className)}>{children}</h3>;
};
