import { FC, PropsWithChildren, useMemo } from 'react';
import cn from 'classnames';

import { Icon, Loader, TargetButton } from '@/components';
import { Modal } from '@/components/Modal';
import { STAKING_STAGE } from '@/types';

import { BaseContainerProps, Props as IModalProps } from '.';

import styles from './styles.module.scss';

interface IProps extends IModalProps {
  onAction: (() => void) | undefined;
}

const ContentCircle: FC<PropsWithChildren<{}>> = ({ children }) => (
  <span className={styles.circle}>{children}</span>
);

const Content: FC<BaseContainerProps> = ({
  currentStep,
  stageName,
  circleContent,
  status,
  title,
  description,
  children,
}) => {
  return (
    <>
      <div className={styles.header}>
        step {currentStep} / 2 <span className={styles.headerStageName}>{stageName}</span>
      </div>
      <ContentCircle>{circleContent}</ContentCircle>
      <span className={styles.status}>{status}</span>
      <p className={styles.title}>{title}</p>
      <p
        className={cn(styles.description, {
          [styles.hidden]: !description,
        })}
      >
        {description}
      </p>
      {children}
    </>
  );
};

export const StakingModal: FC<IProps> = ({ isOpen, stage, onAction, onClose }) => {
  const content = useMemo(() => {
    switch (stage) {
      case STAKING_STAGE.INIT:
        return null;

      case STAKING_STAGE.APPROVING:
        return (
          <Content
            currentStep={1}
            circleContent={<Loader />}
            stageName="approve"
            status="pending"
            title="Please press “Approve” button in MetaMask extension"
            description="ERC20 tokens are deployed with functionality that allows other smart contracts to move tokens.
By approving the smart contracts, it now has permission to execute the peer to peer swapping behavior on your behalf.
The Spend Limit permission is the total amount of tokens that are able to move when using MetaMask Swap."
          />
        );

      case STAKING_STAGE.APPROVED:
        return (
          <Content
            currentStep={1}
            stageName="approve"
            status="approve"
            title="APPROVED successfully. next step..."
            description="ERC20 tokens are deployed with functionality that allows other smart contracts to move tokens.
By approving the smart contracts, it now has permission to execute the peer to peer swapping behavior on your behalf.
The Spend Limit permission is the total amount of tokens that are able to move when using MetaMask Swap."
            circleContent={<Icon name="checkmark" className={styles.icon} />}
          />
        );

      case STAKING_STAGE.APPROVE_ERROR:
        return (
          <Content
            currentStep={1}
            stageName="approve"
            status="rejected"
            title="You rejected Approve transaction in Metamask. Press Approve again to start over or close this window."
            description="ERC20 tokens are deployed with functionality that allows other smart contracts to move tokens.
By approving the smart contracts, it now has permission to execute the peer to peer swapping behavior on your behalf.
The Spend Limit permission is the total amount of tokens that are able to move when using MetaMask Swap."
            circleContent={<Icon name="rejected" className={styles.icon} />}
          >
            <TargetButton className={styles.targetBtn} onClick={onAction}>
              Approve again
            </TargetButton>
          </Content>
        );

      case STAKING_STAGE.SENDING:
        return (
          <Content
            currentStep={2}
            stageName="send"
            status="pending"
            title="Please press Send button in Metamask extension"
            description="Your currency will be transferred from your wallet to the contract address"
            circleContent={<Loader />}
          />
        );

      case STAKING_STAGE.SUCCESS:
        return (
          <Content
            currentStep={2}
            stageName="send"
            status="pending"
            title="sent"
            description="It takes some time for transaction to get confirmed."
            circleContent={<Icon name="checkmark" className={styles.icon} />}
          />
        );

      case STAKING_STAGE.ERROR:
        return (
          <Content
            currentStep={2}
            stageName="send"
            status="rejected"
            title="You rejected Send transaction in Metamask. Press Send again to start over or close this window."
            description={null}
            circleContent={<Icon name="rejected" className={styles.icon} />}
          >
            <TargetButton className={styles.targetBtn} onClick={onAction}>
              Send again
            </TargetButton>
          </Content>
        );

      default:
        return null;
    }
  }, [onAction, stage]);

  return (
    <Modal className={styles.modal} isOpen={isOpen} onClose={onClose}>
      {content}
    </Modal>
  );
};
