import { useMemo } from 'react';
import { observer } from 'mobx-react-lite';

import { BUY_FORM_NODE_ID, TALENTUM_TOKEN } from '@/appConstants';
import { disclaimerImg } from '@/assets/images';
import { Container } from '@/components';
import { useMst } from '@/store';
import { numberFormat } from '@/utils';

import styles from './styles.module.scss';

export const Disclaimer = observer(() => {
  const { crowdsaleToken } = useMst();
  const handleBuy = () => {
    const target = document.getElementById(BUY_FORM_NODE_ID);
    if (target) {
      target.scrollIntoView({
        behavior: 'smooth',
      });
    }
  };

  const maxSupply = crowdsaleToken.isLoadingSuccess ? crowdsaleToken.totalSupply.parsed : '';
  const maxSupplyFormatted = useMemo(
    () => numberFormat.getDigitsGrouped(maxSupply, 3, ','),
    [maxSupply],
  );

  return (
    <section className={styles.section}>
      <Container className={styles.container}>
        <img className={styles.image} src={disclaimerImg} alt={`${TALENTUM_TOKEN} disclaimer`} />
        <div className={styles.content}>
          <h1 className={styles.title}>
            <span>A new era</span> <span>of {TALENTUM_TOKEN}</span> <span>utility</span>
          </h1>
          <div className={styles.description}>
            <p>
              <span className="notranslate">{TALENTUM_TOKEN}</span> Max Supply
            </p>
            <p>
              {maxSupplyFormatted} <span className="notranslate">{TALENTUM_TOKEN}</span>
            </p>
          </div>
          <button className={styles.buyBtn} type="button" onClick={handleBuy}>
            <span className={styles.buyBtnText}>Buy</span>
          </button>
        </div>
      </Container>
    </section>
  );
});
