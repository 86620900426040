import { FC, useMemo, useRef } from 'react';
import { createPortal } from 'react-dom';
import cn from 'classnames';

import { useBodyScrollLock, useClickOutside } from '@/hooks';

import { Props } from '.';

import styles from './Modal.module.scss';

export const Modal: FC<Props> = ({
  className,
  closeBtnClassName,
  children,
  isOpen,
  bodyScrollLock = true,
  defaultStyle = true,
  onClose,
}) => {
  const overlayRef = useRef<HTMLDivElement | null>(null);

  const applyScrollLock = useMemo(() => isOpen && bodyScrollLock, [bodyScrollLock, isOpen]);
  useBodyScrollLock(overlayRef, applyScrollLock);

  const modalRef = useRef<HTMLDivElement | null>(null);

  useClickOutside(modalRef, (e) => {
    // don't know is it working for any cases, but it works for me :D
    if ((e.target as HTMLElement).contains(modalRef.current)) {
      onClose();
    }
  });

  return createPortal(
    isOpen && (
      <div className={styles.overlay} ref={overlayRef}>
        <div
          className={cn(
            {
              [styles.modal]: defaultStyle,
            },
            className,
          )}
          ref={modalRef}
        >
          {children}
          <button
            type="button"
            aria-label="close"
            className={cn(styles.closeBtn, closeBtnClassName)}
            onClick={onClose}
          />
        </div>
      </div>
    ),
    document.body,
  );
};
