import { useCallback, useMemo } from 'react';

import { IOption } from '@/components/LanguageSelect';
import { GoogleTranslateApi } from '@/services/api';
import { useMst } from '@/store';

export const useGoogleTranslate = () => {
  const { user, crowdsale, crowdsaleToken, tokenPrices } = useMst();
  const isGoogleTranslateInitialized = useMemo(
    () =>
      tokenPrices.isLoadingSuccess && crowdsaleToken.isLoadingSuccess && crowdsale.isLoadingSuccess,
    [crowdsale.isLoadingSuccess, crowdsaleToken.isLoadingSuccess, tokenPrices.isLoadingSuccess],
  );

  const handleChangeLanguage = useCallback(
    ({ value: selectedPageLanguage }: IOption) => {
      const isPageLanguageSwitched = GoogleTranslateApi.changeLanguage(selectedPageLanguage);
      if (isPageLanguageSwitched) {
        user.setPageLanguage(selectedPageLanguage);
      }
    },
    [user],
  );

  return {
    isGoogleTranslateInitialized,
    handleChangeLanguage,
  };
};
