import { FC } from 'react';
import cn from 'classnames';

import { appStoreImg, googleChromeImg, googlePlayImg } from '@/assets/images';

import { Link, LinkProps, Props } from '.';

const links: Link[] = [
  {
    to: '#',
    label: 'App store',
    imgPath: appStoreImg,
  },
  {
    to: '#',
    label: 'Google play',
    imgPath: googlePlayImg,
  },
  {
    to: '#',
    label: 'Google chrome',
    imgPath: googleChromeImg,
  },
];

export const DownloadAppLink: FC<LinkProps> = ({ label, to, imgPath, className }) => {
  return (
    <li className={cn(className)}>
      <a href={to} target="_blank" rel="noreferrer">
        <img src={imgPath} alt={label} />
      </a>
    </li>
  );
};

export const DownloadAppLinks: FC<Props> = ({ className, children }) => {
  return <ul className={cn(className)}>{children(links)}</ul>;
};
