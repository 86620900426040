import { FC, useMemo } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';

import { bgNet, bowl } from '@/assets/images';
import { Button, Container, SimpleTitle } from '@/components';
import { useMst } from '@/store';
import { sliceString } from '@/utils';

import styles from './styles.module.scss';

export type Props = {
  className?: string;
};

export const ImportantAddresses: FC<Props> = observer(({ className }) => {
  const { contractAddresses } = useMst();
  const items = useMemo(() => {
    return [
      {
        name: 'Token Contract',
        address: contractAddresses.token,
        isCopyable: true,
      },
      {
        name: 'Crowdsale Contract',
        address: contractAddresses.crowdsale,
        isCopyable: true,
      },
    ];
  }, [contractAddresses.crowdsale, contractAddresses.token]);
  return (
    <section className={cn(className)}>
      <Container className={styles.container}>
        <img className={styles.bgNet} src={bgNet} alt="Talentum contracts" />
        <SimpleTitle className={styles.title}>Important Addresses</SimpleTitle>
        <div className={styles.cards}>
          {items.map(({ name, address, isCopyable }, index) => {
            const shortenedAddress = sliceString(address, 15);
            return (
              <div key={name + address + index.toString()} className={styles.card}>
                {index === items.length - 1 && (
                  <img className={styles.bowlImg} src={bowl} alt="Talentum contracts" />
                )}
                <h5 className={styles.name}>{name}</h5>
                {isCopyable ? (
                  <CopyToClipboard
                    text={address}
                    onCopy={() => toast.success('Contract address is copied!')}
                  >
                    <span>
                      <span className={styles.address}>{shortenedAddress}</span>
                      {isCopyable && <Button classNameChildren={styles.btnText}>Copy</Button>}
                    </span>
                  </CopyToClipboard>
                ) : (
                  <span className={styles.address}>{shortenedAddress}</span>
                )}
                <span className={styles.border} />
              </div>
            );
          })}
        </div>
      </Container>
    </section>
  );
});
