const scriptLoader = async (src: string) => {
  const scriptElement = document.createElement('script');
  scriptElement.src = src;

  const promise = new Promise((resolve) => {
    scriptElement.onload = () => {
      resolve(`Script ${src} loaded!`);
    };
  });

  document.head.append(scriptElement);

  return promise;
};

export default scriptLoader;
