import axios, { AxiosResponse } from 'axios';

import { BASE_URL } from '@/appConstants';
import { IS_PRODUCTION } from '@/config';

import { ICreateSignatureSuccessResponse, IGetAddressesListResponse, IGetTokensResponse } from '..';

const api = axios.create({
  baseURL: IS_PRODUCTION ? `https://${BASE_URL}/api/v1` : `https://${BASE_URL}/api/v1`,
});

/**
 * @returns address_list
 */
const getAddressesList = async () => {
  const { data } = await api.get<{}, AxiosResponse<IGetAddressesListResponse>>('/address/');
  return data;
};

/**
 * @returns signature_create
 */
const createSignature = async (tokenAddress: string, amountToPay: string) => {
  const { data } = await api.post<{}, AxiosResponse<ICreateSignatureSuccessResponse>>(
    '/signature/',
    {
      token_address: tokenAddress,
      amount_to_pay: amountToPay,
    },
    {
      validateStatus(status) {
        return status === 200;
      },
      // headers: {
      //   Host: 'devtalents.rocknblock.io',
      // },
    },
  );
  return data;
};

/**
 * @returns tokens_list
 */
const getTokens = async () => {
  const { data } = await api.get<{}, AxiosResponse<IGetTokensResponse>>('/tokens/');
  return data;
};

export const talentsLiveApi = {
  getAddressesList,
  createSignature,
  getTokens,
};

export * from './types';
