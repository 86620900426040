import BigNumber from 'bignumber.js';

import { BIG_TEN } from '@/appConstants';
import { toBigNumber } from '@/utils';

/**
 * As BigNumber.
 * @param amount 1 (`decimals` = 18)
 * @returns 1000000000000000000 (=`amount` * 10 ** `decimals`)
 */
export const getDecimalAmountBN = (
  amount: BigNumber | string | number,
  decimals = 18,
): BigNumber => {
  return toBigNumber(amount).times(BIG_TEN.pow(decimals));
};

/**
 * @param amount 1 (`decimals` = 18)
 * @returns 1000000000000000000 (=`amount` * 10 ** `decimals`)
 */
export const getDecimalAmount = (amount: BigNumber | string | number, decimals = 18): string => {
  return getDecimalAmountBN(amount, decimals).toFixed();
};

/**
 * As BigNumber.
 * @param decimalAmount 1000000000000000000 (`decimals` = 18)
 * @returns 1 (=`decimalAmount` / 10 ** `decimals`)
 */
export const getBalanceAmountBN = (
  decimalAmount: BigNumber | number | string,
  decimals = 18,
): BigNumber => {
  return toBigNumber(decimalAmount).dividedBy(BIG_TEN.pow(decimals));
};

/**
 * @param decimalAmount 1000000000000000000 (`decimals` = 18)
 * @returns 1 (=`decimalAmount` / 10 ** `decimals`)
 */
export const getBalanceAmount = (
  decimalAmount: BigNumber | number | string,
  decimals = 18,
): string => {
  return getBalanceAmountBN(decimalAmount, decimals).toFixed();
};
