import { FC } from 'react';
import cn from 'classnames';

import { MemoizedCurrencySelect, OPTIONS } from '@/components/CurrencySelect';

import { Props } from '.';

import styles from './InputAmount.module.scss';

export const InputAmount: FC<Props> = ({
  currency,
  className,
  label,
  onChange = () => {},
  onValueChange,
  value,
  name,
  customCurrency,
  ...props
}) => {
  const currencyOption = OPTIONS.find((option) => option.value === currency) || null;

  return (
    <div className={cn(styles.wrap, className)}>
      <span className={cn(styles.label, 'notranslate')}>{label}</span>
      <div className={cn(styles.right, 'notranslate')}>
        {customCurrency || (
          <MemoizedCurrencySelect
            value={currencyOption}
            onChange={(option) => {
              onValueChange(value, option?.value || null);
            }}
          />
        )}
      </div>
      <input
        {...props}
        className={styles.input}
        name={name}
        placeholder="0"
        value={value}
        onChange={(e) => {
          onChange(e);
          onValueChange(e.target.value, currency);
        }}
      />
    </div>
  );
};
