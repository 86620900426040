import { useEffect } from 'react';

export function useScroll(handler: ((e: Event) => void) | null) {
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (handler) {
      window.addEventListener('scroll', handler);
      return () => window.removeEventListener('scroll', handler);
    }
  }, [handler]);
}
