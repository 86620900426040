import { FC, useMemo } from 'react';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';

import { TALENTUM_TOKEN } from '@/appConstants';
import { Icon, Tooltip } from '@/components';
import { useMst } from '@/store';
import { TCurrency } from '@/types';
import { getFormattedDateTimeLeft, numberFormatter, toBigNumber } from '@/utils';

import { Props } from '.';

import styles from './BuyFormStats.module.scss';

interface ITokenRate {
  currency: TCurrency;
  price: string;
}

export const BuyFormStats: FC<Props> = observer(({ className }) => {
  const { tokenPrices, crowdsale } = useMst();
  const tokensSold = crowdsale.getTotalSold;
  const tokensSoldFormattedToDisplay = useMemo(
    () => (tokensSold !== 'NaN' ? numberFormatter(tokensSold) : 'Loading...'),
    [tokensSold],
  );
  const totalTokensToBeSold = crowdsale.getHardcap;
  const totalTokensToBeSoldFormatted = numberFormatter(totalTokensToBeSold);

  const talentumTokenUsdPrice = !crowdsale.isLoadingSuccess
    ? 'Loading...'
    : `${crowdsale.tokenPrice}$`;

  const { daysLeft, timeLeftPostfix } = getFormattedDateTimeLeft();

  const ratesList = () => {
    const tokenData = tokenPrices.data;
    return tokenData.map(({ symbol, price }) => {
      return {
        currency: symbol.toLocaleLowerCase(),
        price,
      } as ITokenRate;
    });
  };

  const progressValueAsPercents = toBigNumber(tokensSold)
    .dividedBy(crowdsale.getHardcap)
    .multipliedBy(100)
    .toFixed();

  return (
    <ul className={cn(className, styles.list)}>
      <li className={styles.item}>
        <div className={styles.daysLeft}>
          <span className={styles.daysLeftCount}>{daysLeft}</span>
          {timeLeftPostfix}
        </div>
        <div className={styles.progressBar}>
          <span
            style={{ width: `${progressValueAsPercents}%` }}
            className={styles.progressBarLine}
          />
        </div>
        <Tooltip event="click" content={tokensSold}>
          <p className={styles.progressDescription}>
            {tokensSoldFormattedToDisplay} of {totalTokensToBeSoldFormatted} sold
          </p>
        </Tooltip>
      </li>
      <li className={styles.item}>
        <span className={styles.priceValue}>{talentumTokenUsdPrice}</span>
        <span className={styles.priceDescription}>Current {TALENTUM_TOKEN} Price</span>
      </li>
      <li className={styles.item}>
        <p className={styles.ratesTitle}>Current rates:</p>
        <ul className={styles.ratesList}>
          {ratesList().map(({ currency, price }) => {
            return (
              <li key={currency} className={styles.rateItem}>
                <span className={styles.rateIcon}>
                  <Icon name={currency} />
                </span>
                <span>$</span>
                {price}
              </li>
            );
          })}
        </ul>
      </li>
    </ul>
  );
});
