import { TCurrency, TIcons } from '@/types';

import { Option } from '.';

export const OPTIONS: Option[] = [
  {
    value: 'usdt',
    label: 'usdt',
  },
  {
    value: 'bnb',
    label: 'bnb',
  },
  {
    value: 'busd',
    label: 'busd',
  },
  {
    value: 'weth',
    label: 'weth',
  },
];

export const ICON_MAPPER: Record<TCurrency, TIcons> = {
  usdt: 'usdt',
  busd: 'busd',
  bnb: 'bnb',
  weth: 'weth',
};
