/* eslint-disable no-param-reassign */
import { toast } from 'react-toastify';
import { flow, getRoot, types } from 'mobx-state-tree';

import { TALENTUM_TOKEN } from '@/appConstants';
import { TLTMContract } from '@/services';
import { FetchStatus, RootInstance } from '@/types';
import { clog, clogError } from '@/utils';

import { rootStore } from '..';

import { BigNumberValue } from '.';

export const CrowdsaleToken = types
  .model({
    fetchStatus: types.enumeration<FetchStatus>('fetchStatus', Object.values(FetchStatus)),
    symbol: types.string,
    decimals: types.number,
    totalSupply: BigNumberValue,
  })
  .actions((self) => {
    const setSymbol = (value: string) => {
      self.symbol = value;
    };
    const setDecimals = (value: string | number) => {
      self.decimals = Number(value);
    };
    const setTotalSupply = (raw: string, decimals: number) => {
      self.totalSupply.set(raw, decimals);
    };
    const fetchSymbol = flow(function* fetchSymbol() {
      try {
        const symbol = yield TLTMContract.symbol();
        setSymbol(symbol);
      } catch (err) {
        clog('[Crowdsale Token]: fetchSymbol', err);
      }
    });
    const fetchDecimals = flow(function* fetchDecimals() {
      try {
        const decimals = yield TLTMContract.decimals();
        setDecimals(decimals);
      } catch (err) {
        clog('[Crowdsale Token]: fetchDecimals', err);
      }
    });
    const fetchTotalSupply = flow(function* fetchTotalSupply() {
      if (self.decimals === -1) {
        yield fetchDecimals();
      }
      try {
        const totalSupply = yield TLTMContract.totalSupply();
        setTotalSupply(totalSupply, self.decimals);
      } catch (err) {
        clog('[Crowdsale Token]: fetchTotalSupply', err);
      }
    });

    const fetchTLTMData = flow(function* fetchTLTMData() {
      self.fetchStatus = FetchStatus.loading;
      if (!getRoot<RootInstance>(self).user.isContractsExists) yield Promise.resolve(undefined);
      try {
        yield Promise.all([fetchSymbol(), fetchTotalSupply()]);
        self.fetchStatus = FetchStatus.success;
      } catch (err) {
        clogError(err);
        toast.error(`Error fetching ${TALENTUM_TOKEN} data`);
        self.fetchStatus = FetchStatus.error;
      }
    });

    const fetchUserBalance = flow(function* fetchUserBalance(address: string) {
      if (self.decimals !== -1) {
        yield Promise.resolve(undefined);
      }
      try {
        const balance = yield TLTMContract.balanceOf(address);
        rootStore.user.setBalance('TLTM', balance, self.decimals);
      } catch (err) {
        clog('[Crowdsale Token]: fetchUserBalance', err);
      }
    });

    return {
      setTotalSupply,
      fetchTLTMData,
      fetchSymbol,
      fetchUserBalance,
    };
  })
  .views((self) => ({
    get isLoading() {
      return self.fetchStatus === FetchStatus.loading;
    },
    get isLoadingError() {
      return self.fetchStatus === FetchStatus.error;
    },
    get isLoadingSuccess() {
      return self.fetchStatus === FetchStatus.success;
    },
  }));
