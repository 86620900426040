import { TLanguageCode } from '@/types';
import scriptLoader from '@/utils/scriptLoader';

export const GoogleTranslateApi = {
  url: 'https://translate.google.com/translate_a/element.js',

  initGoogleTranslate() {
    // @ts-expect-error Google is a window.google object
    // eslint-disable-next-line no-undef
    google.translate.TranslateElement(
      {
        pageLanguage: 'en',
        includedLanguages: 'en,es,ru,zh-TW,de,fr,ar',
      },
      'google_translate_element',
    );
  },

  async init() {
    await scriptLoader(this.url);
    return new Promise((resolve, reject) => {
      let isFirstTry = true;
      const loader = () => {
        try {
          const res = this.initGoogleTranslate();
          resolve(res);
        } catch (err) {
          isFirstTry = false;
          if (isFirstTry) {
            setTimeout(loader, 1000);
            return;
          }
          reject(new Error('Failed to initialize Google Translate API'));
        }
      };
      setTimeout(loader, 1000);
    });
  },

  changeLanguage(langCode: TLanguageCode) {
    const select = document.querySelector<HTMLSelectElement>('#google_translate_element select');
    if (select) {
      select.value = langCode;
      select.dispatchEvent(new Event('change'));
      return true;
    }
    return false;
  },
};
