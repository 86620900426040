import { memo } from 'react';
import cn from 'classnames';

import { LINKS } from '@/appConstants';
import { SocialLink, SocialLinks } from '@/components';

import styles from './HeaderExternalLinks.module.scss';

export type Props = {
  className?: string;
  classNameItem?: string;
  classNameLink?: string;
};

export const HeaderExternalLinks = memo<Props>(({ className, classNameLink, classNameItem }) => {
  return (
    <>
      <ul className={cn(className, 'notranslate')}>
        {LINKS.map((link) => (
          <li key={link.label} className={cn(classNameItem)}>
            <a
              href={link.to}
              className={cn(styles.externalLink, classNameLink)}
              target="_blank"
              rel="noreferrer"
            >
              {link.label}
            </a>
          </li>
        ))}
      </ul>
      <SocialLinks className={styles.socialLinks}>
        {(links) =>
          links.map((link) => (
            <SocialLink
              key={link.icon}
              className={styles.socialLink}
              to={link.to}
              icon={link.icon}
            />
          ))
        }
      </SocialLinks>
    </>
  );
});
HeaderExternalLinks.displayName = 'HeaderExternalLinks';
