import { useCallback } from 'react';
import { INoNameContract } from '@amfi/connect-wallet/dist/interface';
import { AbiItem } from 'web3-utils';

import { MAX_UINT_256 } from '@/appConstants';
import { WalletService, walletService } from '@/services';

interface IApproveToken {
  token: INoNameContract;
  spender: string;
  walletAddress?: string;
}

interface ITokenAllowance {
  token: INoNameContract;
  walletAddress?: string;
  spender: string;
}

interface IUseAllowanceReturnType {
  tokenAllowance: ({ token, walletAddress, spender }: ITokenAllowance) => Promise<string>;
  checkAllowance: (tokenAllowanceArgs: ITokenAllowance) => Promise<string>;
  approveToken: ({ token, spender, walletAddress }: IApproveToken) => unknown;
}

export const useAllowance = (): IUseAllowanceReturnType => {
  const tokenAllowance = useCallback(
    async ({ token, walletAddress = walletService.walletAddress, spender }: ITokenAllowance) => {
      const tokenContract = walletService.connectWallet.getContract({
        abi: token.abi,
        address: token.address,
      });

      try {
        return await tokenContract.methods.allowance(walletAddress, spender).call();
      } catch (error) {
        return error;
      }
    },
    [],
  );

  const checkAllowance = useCallback(
    async (tokenAllowanceArgs: ITokenAllowance) => {
      try {
        const allowance = await tokenAllowance(tokenAllowanceArgs);
        return allowance;
      } catch (error) {
        return '0';
      }
    },
    [tokenAllowance],
  );

  const approveToken = useCallback(
    ({ token, spender, walletAddress = walletService.walletAddress }: IApproveToken) => {
      const abiMethodInterface = WalletService.getMethodInterface(
        token.abi as AbiItem[],
        'approve',
      );
      const encodedData = walletService.encodeFunctionCall(abiMethodInterface, [
        spender,
        MAX_UINT_256,
      ]);

      try {
        return walletService.sendTransaction({
          from: walletAddress,
          to: token.address,
          data: encodedData,
        });
      } catch (error) {
        return error;
      }
    },
    [],
  );
  return {
    tokenAllowance,
    checkAllowance,
    approveToken,
  };
};
