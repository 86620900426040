import { flow, getRoot, types } from 'mobx-state-tree';
import { AbiItem } from 'web3-utils';

import { contracts } from '@/config';
import { walletService } from '@/services';
import { IGetAddressesListResponse, talentsLiveApi } from '@/services/api';
import { ContractsWithDataEnum, RootInstance } from '@/types';
import { clog, clogError } from '@/utils';

const modelArgs = {
  token: types.string,
  crowdsale: types.string,
};

/* eslint-disable no-param-reassign */
export const ContractAddresses = types.model(modelArgs).actions((self) => {
  const addContracts = flow(function* addContracts(
    contracts: {
      name: ContractsWithDataEnum;
      address: string;
      abi: AbiItem[];
    }[],
  ) {
    try {
      yield Promise.all(
        contracts.map((contractData) => {
          return walletService.connectWallet.addContract(contractData);
        }),
      );
      getRoot<RootInstance>(self).user.initializeContracts();
    } catch (err) {
      clog('initializeContracts', err);
    }
  });
  const setAddress = (key: keyof typeof modelArgs, address: string) => {
    self[key] = address;
  };
  const fetchContractAddresses = flow(function* fetchContractAddresses() {
    try {
      const contractAddresses: IGetAddressesListResponse = yield talentsLiveApi.getAddressesList();
      setAddress('token', contractAddresses['token address']);
      setAddress('crowdsale', contractAddresses['contract address']);
      yield addContracts([
        {
          name: ContractsWithDataEnum.TLTM,
          abi: contracts.params.TLTM.abi,
          address: contractAddresses['token address'],
        },
        {
          name: ContractsWithDataEnum.TLTMCrowdsale,
          abi: contracts.params.TLTMCrowdsale.abi,
          address: contractAddresses['contract address'],
        },
      ]);
    } catch (err) {
      clogError(err);
    }
  });

  return {
    addContracts,
    setAddress,
    fetchContractAddresses,
  };
});
