/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';

import { COPYRIGHT_TEXT } from '@/appConstants';
import {
  ButtonForwardedRef,
  Container,
  DeveloperLogo,
  DownloadAppLink,
  DownloadAppLinks,
  MemoizedLanguageSelect,
  SocialLink,
  SocialLinks,
  Tooltip,
} from '@/components';
import { OPTIONS_AS_MAP } from '@/components/LanguageSelect';
import { HeaderConnectionMenu, HeaderExternalLinks } from '@/containers/Header';
import { useGoogleTranslate } from '@/hooks';
import { useMst } from '@/store';

import { WalletConnected } from '../WalletConnected';

import styles from './HeaderMobileMenu.module.scss';

export type Props = {
  className?: string;
};

export const HeaderMobileMenu: FC<Props> = observer(({ className }) => {
  const { user } = useMst();
  const { isGoogleTranslateInitialized, handleChangeLanguage } = useGoogleTranslate();

  return (
    <div className={cn(styles.menu, className)}>
      <Container className={styles.container}>
        <HeaderExternalLinks className={styles.externalLinks} classNameItem={styles.item} />
        {user.isConnectedWallet ? (
          <WalletConnected />
        ) : (
          <Tooltip event="click" content={<HeaderConnectionMenu />} positionY="bottom">
            <ButtonForwardedRef className={styles.connectBtn} type="button">
              Connect wallet
            </ButtonForwardedRef>
          </Tooltip>
        )}

        <a href="#" target="_blank" className={cn(styles.links, 'notranslate')}>
          Talents.live
        </a>
        <p className={styles.description}>
          is our free of charge socialmedia app for creative talents, fans such as talent-scouts and
          agencies
        </p>
        <DownloadAppLinks className={styles.downloadLinks}>
          {(links) =>
            links.map((link) => (
              <DownloadAppLink {...link} key={link.label} className={styles.downloadLink} />
            ))
          }
        </DownloadAppLinks>
        <MemoizedLanguageSelect
          className={styles.languageSelect}
          value={OPTIONS_AS_MAP[user.pageLanguage]}
          isDisabled={!isGoogleTranslateInitialized}
          onChange={(option) => {
            if (option) {
              handleChangeLanguage(option);
            }
          }}
        />
      </Container>

      <div className={styles.footer}>
        <Container>
          <SocialLinks className={styles.socialLinks}>
            {(links) =>
              links.map((link) => (
                <SocialLink
                  key={link.icon}
                  className={styles.socialLink}
                  to={link.to}
                  icon={link.icon}
                />
              ))
            }
          </SocialLinks>
          <DeveloperLogo className={styles.developer} />
          <p className={styles.copyright}>{COPYRIGHT_TEXT}</p>
        </Container>
      </div>
    </div>
  );
});
HeaderMobileMenu.displayName = 'HeaderMobileMenu';
