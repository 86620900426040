import { FC, forwardRef } from 'react';
import cn from 'classnames';

import { Props } from '.';

import styles from './styles.module.scss';

export const Button: FC<Props> = ({
  className,
  classNameChildren,
  _ref,
  children,
  disabled = false,
  ...props
}) => {
  return (
    // eslint-disable-next-line react/button-has-type
    <button
      ref={_ref}
      className={cn(styles.btn, className, {
        [styles.btn_disabled]: disabled,
      })}
      disabled={disabled}
      {...props}
    >
      <span className={cn(styles.children, classNameChildren)}>{children}</span>
    </button>
  );
};

export const TargetButton: FC<Props> = ({
  className,
  classNameChildren,
  _ref,
  children,
  disabled = false,
  ...props
}) => {
  return (
    // eslint-disable-next-line react/button-has-type
    <button
      ref={_ref}
      className={cn(styles.targetBtn, className, {
        [styles.btn_disabled]: disabled,
      })}
      disabled={disabled}
      {...props}
    >
      <span className={cn(styles.children, classNameChildren)}>{children}</span>
    </button>
  );
};

export const ButtonForwardedRef = forwardRef<HTMLButtonElement, Props>((props, ref) => {
  return <Button {...props} _ref={ref} />;
});
ButtonForwardedRef.displayName = 'ButtonForwardedRef';
