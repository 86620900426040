import { ContractSendMethod } from 'web3-eth-contract';

import { ContractsWithDataEnum } from '@/types';

import { walletService } from '..';

interface ReadMethods {
  price: () => ContractSendMethod;
  determineStage: () => ContractSendMethod;
  SOFT_CAP: () => ContractSendMethod;
  LIMITS: (stageIndex: number) => ContractSendMethod;
  STAGES: (stageIndex: number) => ContractSendMethod;

  totalSold: () => ContractSendMethod;
  startTime: () => ContractSendMethod;

  PAYMENT_METHODS: (index: string) => ContractSendMethod;
  amounts: (address: string, index: number) => ContractSendMethod;
  amountsSold: (stageIndex: number) => ContractSendMethod;
}

interface WriteMethods {
  buy: (
    tokenToPay: string,
    amountToPay: string,
    amountToReceive: string,
    endTime: string,
    signature: string,
  ) => ContractSendMethod;

  redeem: () => ContractSendMethod;
  refund: () => ContractSendMethod;
}

type ContractMethods = ReadMethods & WriteMethods;

export class TLTMCrowdsale {
  static get contract() {
    return walletService.connectWallet.Contract(ContractsWithDataEnum.TLTMCrowdsale);
  }

  static get contractMethods() {
    return TLTMCrowdsale.contract.methods as ContractMethods;
  }

  static getPaymentMethod(...args: Parameters<ReadMethods['PAYMENT_METHODS']>): Promise<string> {
    return TLTMCrowdsale.contractMethods.PAYMENT_METHODS(...args).call();
  }

  static amounts(...args: Parameters<ReadMethods['amounts']>): Promise<string> {
    return TLTMCrowdsale.contractMethods.amounts(...args).call();
  }

  static amountsSold(...args: Parameters<ReadMethods['amountsSold']>): Promise<string> {
    return TLTMCrowdsale.contractMethods.amountsSold(...args).call();
  }

  static getCurrentStageTokenPrice(): Promise<string> {
    return TLTMCrowdsale.contractMethods.price().call();
  }

  static getCurrentStageIndex(): Promise<string> {
    return TLTMCrowdsale.contractMethods.determineStage().call();
  }

  static getSoftcap(): Promise<string> {
    return TLTMCrowdsale.contractMethods.SOFT_CAP().call();
  }

  static getStageLimits(...args: Parameters<ReadMethods['LIMITS']>): Promise<string> {
    return TLTMCrowdsale.contractMethods.LIMITS(...args).call();
  }

  static getStageEndTimestamp(...args: Parameters<ReadMethods['STAGES']>): Promise<string> {
    return TLTMCrowdsale.contractMethods.STAGES(...args).call();
  }

  static getTotalSold(): Promise<string> {
    return TLTMCrowdsale.contractMethods.totalSold().call();
  }

  static startTime(): Promise<string> {
    return TLTMCrowdsale.contractMethods.startTime().call();
  }

  // Write
  static buy(...args: Parameters<WriteMethods['buy']>) {
    return walletService.createTransaction('buy', args, ContractsWithDataEnum.TLTMCrowdsale);
  }

  static redeem(...args: Parameters<WriteMethods['redeem']>) {
    return walletService.createTransaction('redeem', args, ContractsWithDataEnum.TLTMCrowdsale);
  }

  static refund(...args: Parameters<WriteMethods['refund']>) {
    return walletService.createTransaction('refund', args, ContractsWithDataEnum.TLTMCrowdsale);
  }
}
