import { FC, useEffect, useRef } from 'react';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';

import { STAGE_DURATION_AS_WEEKS } from '@/appConstants';
import { bgNet, Checkmark } from '@/assets/images';
import { Container, Title } from '@/components';
import { useMst } from '@/store';

import { getStageData } from './helpers';

import styles from './PresalePlan.module.scss';

export type Props = {
  className?: string;
  shouldCenterActiveStage?: boolean;
};

export const PresalePlan: FC<Props> = observer(({ className, shouldCenterActiveStage = true }) => {
  const { crowdsaleToken, crowdsale } = useMst();

  const listContainerRef = useRef<HTMLUListElement | null>(null);
  const activeStageRef = useRef<HTMLLIElement | null>(null);

  const stages = getStageData(crowdsale);

  useEffect(() => {
    if (shouldCenterActiveStage && activeStageRef.current && listContainerRef.current) {
      // @see https://stackoverflow.com/questions/580605/horizontal-scrolling-javascript
      listContainerRef.current.scrollLeft =
        activeStageRef.current.offsetLeft - activeStageRef.current.clientWidth / 2;
    }
  });

  return (
    <section className={cn(styles.section, className)}>
      <Container className={styles.container}>
        <img className={styles.bgNet} src={bgNet} alt="" />
        <Title className={styles.title}>Presale plan</Title>
        <ul className={styles.list} ref={listContainerRef}>
          {stages.map(({ id, status, amount, price, isActive, isCompleted }, index) => (
            <li
              key={id}
              className={cn(styles.stage, {
                [styles.active]: isActive,
                [styles.completed]: isCompleted,
              })}
              ref={isActive ? activeStageRef : undefined}
            >
              <div className={styles.header}>
                <span className={styles.status}>{`stage ${index + 1} - ${status}`}</span>
              </div>
              <div className={styles.amount}>
                <Checkmark
                  className={cn(styles.checkmark, {
                    [styles.hidden]: !isCompleted,
                  })}
                />
                {amount}
              </div>
              <span className={styles.currency}>{crowdsaleToken.symbol}</span>
              <span className={styles.price}>{`@ $${price}`}</span>
              <span className={styles.weeks}>({STAGE_DURATION_AS_WEEKS} weeks)</span>
              <span className={styles.border} />
            </li>
          ))}
        </ul>
      </Container>
    </section>
  );
});
