export class LocalStorageWrapper<KeyType extends string> {
  public projectId: string;

  constructor(projectId: string) {
    this.projectId = projectId;
  }

  private getPrefixedKey(key: KeyType): string {
    return `${this.projectId}_${key}`;
  }

  getItem(key: KeyType): string | null {
    return localStorage.getItem(this.getPrefixedKey(key));
  }

  setItem(key: KeyType, value: string): void {
    localStorage.setItem(this.getPrefixedKey(key), value);
  }

  removeItem(key: KeyType): void {
    localStorage.removeItem(this.getPrefixedKey(key));
  }

  clear(): void {
    localStorage.clear();
  }

  key(index: number): string | null {
    return localStorage.key(index);
  }

  get length(): number {
    return localStorage.length;
  }
}
