import { bep20Abi, TLTMAbi, TLTMCrowdsaleAbi } from '@/config';
import { ChainsEnum, ContractsEnum, ContractsWithDataEnum, IContracts } from '@/types';

import { IS_PRODUCTION } from './constants';

export const contracts: IContracts = {
  type: IS_PRODUCTION ? 'mainnet' : 'testnet',
  names: Object.keys(ContractsWithDataEnum),
  decimals: 18,
  params: {
    [ContractsEnum.bep20]: {
      address: {
        [ChainsEnum.BinanceSmartChain]: IS_PRODUCTION ? '' : '',
      },
      abi: bep20Abi,
    },
    [ContractsWithDataEnum.TLTM]: {
      address: {
        [ChainsEnum.BinanceSmartChain]: IS_PRODUCTION ? '' : '',
      },
      abi: TLTMAbi,
    },
    [ContractsWithDataEnum.TLTMCrowdsale]: {
      address: {
        [ChainsEnum.BinanceSmartChain]: IS_PRODUCTION ? '' : '',
      },
      abi: TLTMCrowdsaleAbi,
    },
  },
};
