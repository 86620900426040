import { FC, useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import { observer } from 'mobx-react-lite';

import { sportsPeopleImg } from '@/assets/images';
import {
  Bonuses,
  BuyForm,
  Disclaimer,
  Features,
  Footer,
  Header,
  ImportantAddresses,
  PresalePlan,
  ProjectInfo,
  Team,
} from '@/containers';
import { WalletConnectProvider } from '@/services';
import { GoogleTranslateApi } from '@/services/api';
import { rootStore, StoreProvider, useMst } from '@/store';

import 'react-toastify/dist/ReactToastify.css';
import styles from './App.module.scss';

const Providers: FC = ({ children }) => (
  <StoreProvider value={rootStore}>
    <WalletConnectProvider>{children}</WalletConnectProvider>
  </StoreProvider>
);

const HomeRoute: FC = observer(() => {
  const { tokenPrices, crowdsaleToken, contractAddresses, crowdsale, user } = useMst();

  useEffect(() => {
    contractAddresses.fetchContractAddresses();
  }, [contractAddresses]);

  useEffect(() => {
    if (user.isContractsExists) {
      tokenPrices.fetchTokenPrices();
    }
  }, [tokenPrices, user.isContractsExists]);

  useEffect(() => {
    if (user.isContractsExists) {
      crowdsaleToken.fetchTLTMData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.isContractsExists]);

  useEffect(() => {
    if (user.isContractsExists) {
      crowdsale.fetchCrowdsaleData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.isContractsExists]);

  useEffect(() => {
    if (user.address && user.isContractsExists) {
      crowdsaleToken.fetchUserBalance(user.address);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [crowdsaleToken.decimals, user.address, user.isContractsExists]);

  useEffect(() => {
    if (tokenPrices.isLoadingSuccess && user.isContractsExists) {
      crowdsale.fetchPaymentMethodsIndexes(tokenPrices.data.length);
    }
  }, [crowdsale, tokenPrices.data.length, tokenPrices.isLoadingSuccess, user.isContractsExists]);

  useEffect(() => {
    if (user.address && crowdsale.paymentMethods.size && user.isContractsExists) {
      crowdsale.fetchUserTokensAmounts();
    }
  }, [crowdsale, user.address, crowdsale.paymentMethods.size, user.isContractsExists]);

  useEffect(() => {
    if (
      crowdsale.isLoadingSuccess &&
      crowdsaleToken.isLoadingSuccess &&
      tokenPrices.isLoadingSuccess
    ) {
      GoogleTranslateApi.init();
    }
  }, [crowdsale.isLoadingSuccess, crowdsaleToken.isLoadingSuccess, tokenPrices.isLoadingSuccess]);

  return (
    <>
      <div className={styles.preview}>
        <Header />
        <Disclaimer />
      </div>

      <main>
        <ProjectInfo />
        <Features className={styles.features} />
        <Bonuses className={styles.bonuses} />
        <PresalePlan className={styles.presalePlan} />
        <BuyForm className={styles.buyForm} />
        <img className={styles.sportsPeople} src={sportsPeopleImg} alt="" />
        <ImportantAddresses className={styles.addresses} />
        <Team className={styles.team} />
      </main>
      <Footer className={styles.footer} />
    </>
  );
});

export const App = () => {
  return (
    <>
      <ToastContainer limit={3} />
      <Providers>
        <HomeRoute />
      </Providers>
    </>
  );
};
