/**
 *
 * @param phrase
 * @param startSymbols defaults 7
 * @param endSymbols defaults 4
 * @returns
 */
export const sliceString = (phrase: string | number, startSymbols = 7, endSymbols = 4): string => {
  const strPhrase = `${phrase}`;

  return strPhrase.length > startSymbols + endSymbols
    ? `${strPhrase.slice(0, startSymbols)}...${strPhrase.slice(
        strPhrase.length - endSymbols,
        strPhrase.length,
      )}`
    : strPhrase;
};
