import { FC, ReactNode, useCallback } from 'react';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';

import { MetamaskIcon, WalletIcon } from '@/assets/images';
import { useWalletConnectorContext } from '@/services';
import { useMst } from '@/store';
import { ProvidersEnum, WalletConnectionPlatform } from '@/types';

import styles from './HeaderConnectionMenu.module.scss';

export type Props = {
  className?: string;
};

interface IProviderOption {
  providerName: ProvidersEnum;
  label: WalletConnectionPlatform;
  icon: ReactNode;
}

const options: IProviderOption[] = [
  {
    providerName: ProvidersEnum.MetaMask,
    label: WalletConnectionPlatform.Metamask,
    icon: <MetamaskIcon className={styles.icon} />,
  },
  {
    providerName: ProvidersEnum.WalletConnect,
    label: WalletConnectionPlatform.WalletConnect,
    icon: <WalletIcon className={styles.icon} />,
  },
];

export const HeaderConnectionMenu: FC<Props> = observer(({ className }) => {
  const { connect } = useWalletConnectorContext();
  const { user } = useMst();

  const handleProviderConnectClick = useCallback(
    (provider: ProvidersEnum) => {
      connect(user.network, provider);
    },
    [connect, user],
  );
  return (
    <ul className={cn(styles.options, className)}>
      {options.map(({ providerName, icon, label }) => (
        <li key={label} className={styles.option}>
          <button
            type="button"
            className={styles.btn}
            onClick={() => handleProviderConnectClick(providerName)}
          >
            {icon}
            {label}
          </button>
        </li>
      ))}
    </ul>
  );
});
