import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import BigNumber from 'bignumber.js';

import { contracts } from '@/config';
import { walletService } from '@/services';
import { useMst } from '@/store';
import { ContractsWithDataEnum, Currency } from '@/types';
import { clogError, isNativeCurrency } from '@/utils';

import { useAllowance } from '.';

interface IUseApproveReturnType {
  isApproving: boolean;
  approve: () => Promise<void>;
  hasAllowance: boolean;
  setMinAllowanceInWei: Dispatch<SetStateAction<string>>;
}

export const useApprove = (
  tokenName: Currency,
  spenderName: ContractsWithDataEnum,
): IUseApproveReturnType => {
  const [minAllowanceInWei, setMinAllowanceInWei] = useState('0');
  const [hasAllowance, setAllowance] = useState(false);
  const [isApproving, setApproving] = useState(false);
  const { checkAllowance: requestCheckAllowance, approveToken } = useAllowance();
  const { user, tokenPrices } = useMst();

  const isNativeToken = useMemo(
    () => isNativeCurrency(tokenName, user.network),
    [tokenName, user.network],
  );

  const params = useMemo(() => {
    if (!tokenPrices.isLoadingSuccess) return undefined;
    if (isNativeToken) return undefined; // to prevent fetch requests
    const [tokenData] = tokenPrices.getTokenDataBySymbol(tokenName);
    return {
      token: {
        abi: contracts.params.bep20.abi,
        address: tokenData.address,
      },
      spender: walletService.connectWallet.Contract(spenderName).options.address,
      walletAddress: user.address,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenPrices.isLoadingSuccess, tokenName, spenderName, user.network, user.address]);

  const checkAllowance = useCallback(async () => {
    if (user.isConnectedWallet && params) {
      try {
        const allowance = await requestCheckAllowance(params);
        const isAllowed = new BigNumber(minAllowanceInWei).isLessThan(allowance);
        setAllowance(isAllowed);
      } catch (error) {
        clogError('useApprove: checkAllowance', error);
      }
    }
  }, [minAllowanceInWei, params, requestCheckAllowance, user.isConnectedWallet]);

  const approve = useCallback(async () => {
    if (user.isConnectedWallet && params) {
      setApproving(true);
      try {
        await approveToken(params);
        setAllowance(true);
      } catch (error) {
        clogError('useApprove: approve', error);
        throw error;
      } finally {
        setApproving(false);
      }
    }
  }, [user.isConnectedWallet, approveToken, params]);

  useEffect(() => {
    checkAllowance();
  }, [checkAllowance]);

  useEffect(() => {
    if (isNativeToken) {
      setAllowance(true);
    }
  }, [isNativeToken]);

  return {
    isApproving,
    approve,
    hasAllowance,
    setMinAllowanceInWei,
  };
};
