import { FC } from 'react';
import cn from 'classnames';

import { Props } from '.';

import styles from './styles.module.scss';

export const Burger: FC<Props> = ({ className, isActive, ...props }) => {
  return (
    <button
      type="button"
      {...props}
      className={cn(styles.btn, className, {
        [styles.active]: isActive,
      })}
    >
      <span />
    </button>
  );
};
