/* eslint-disable max-len */
import { FC } from 'react';
import cn from 'classnames';

import { bgNet, person1, person2, person3 } from '@/assets/images';
import { Container, SimpleTitle } from '@/components';

import styles from './Team.module.scss';

export type Props = {
  className?: string;
};

interface IMember {
  id: number;
  name: string;
  position: string;
  description: string;
  avatar: string;
}

const members: IMember[] = [
  {
    id: 1,
    name: 'Thomas May',
    position: '',
    description: `Thomas is always enthusiastic about new technologies and trends. He quickly familiarizes himself with complex structures and brings them into our project.

      At Talents, Thomas is responsible for the strategic direction of the company.`,
    avatar: person1,
  },
  {
    id: 2,
    name: 'Hicham Aouraghe',
    position: '',
    description: `Hicham has been a successful entrepreneur in Germany for years. The ability to form and lead successful teams is one of his great strengths. He pursues his goals very purposefully and with great passion.


      As the founder and idea generator, Hicham represents the Talentsverse company`,
    avatar: person2,
  },
  {
    id: 3,
    name: 'Mohamed Mayati',
    position: '',
    description: `Mohamed is a self taught visual artist and programmer with proven experience taking care of all visual matters while taking part in the front end development.

    At Talents, Mohamed brings new ideas to life, that emphasize what Talents is about, and takes a big part in the visual and User Experience design process when it comes to our software.`,
    avatar: person3,
  },
];

export const Team: FC<Props> = ({ className }) => {
  return (
    <section className={cn(styles.section, className)}>
      <Container className={styles.container}>
        <img className={styles.bgNet} src={bgNet} alt="Talentum team" />
        <SimpleTitle className={styles.title}>Team</SimpleTitle>
        <ul className={styles.members}>
          {members.map(({ id, name, position, description, avatar }) => (
            <li key={id} className={styles.member}>
              <span
                style={{
                  background: `center 100% / contain no-repeat url("${avatar}"), linear-gradient(180deg, #FFC0EF 0%, #FF6038 100%)`,
                }}
                className={styles.photo}
              />
              <h5 className={styles.name}>{name}</h5>
              <span className={styles.position}>{position}</span>
              <p className={styles.description}>{description}</p>
            </li>
          ))}
        </ul>
      </Container>
    </section>
  );
};
