import { FC, memo, useCallback, useState } from 'react';
import ReactSelect from 'react-select';
import cn from 'classnames';

import {
  Control,
  DropdownIndicator,
  IndicatorsContainer,
  Menu,
  OptionComponent,
  OPTIONS,
  Props,
  SingleValue,
  ValueContainer,
} from '.';

import styles from './CurrencySelect.module.scss';

export const CurrencySelect: FC<Props> = ({ value, className, onChange }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const handleMenuOpen = useCallback(() => setIsMenuOpen(true), []);
  const handleMenuClose = useCallback(() => setIsMenuOpen(false), []);
  return (
    <ReactSelect
      className={cn(styles.select, className, 'notranslate')}
      styles={{
        dropdownIndicator: (provided) => ({
          ...provided,
          transform: isMenuOpen ? 'rotate(270deg)' : 'rotate(90deg)',
        }),
      }}
      value={value}
      isMulti={false}
      isSearchable={false}
      options={OPTIONS}
      components={{
        ClearIndicator: () => null,
        IndicatorSeparator: () => null,
        Menu,
        Control,
        ValueContainer,
        SingleValue,
        Option: OptionComponent,
        DropdownIndicator,
        IndicatorsContainer,
      }}
      onMenuOpen={handleMenuOpen}
      onMenuClose={handleMenuClose}
      onChange={onChange}
    />
  );
};

export const MemoizedCurrencySelect = memo<Props>(CurrencySelect);
