import { FC, useMemo } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import { observer } from 'mobx-react-lite';

import { logoutImg } from '@/assets/images';
import { Button } from '@/components';
import { useWalletConnectorContext } from '@/services';
import { useMst } from '@/store';
import { sliceString } from '@/utils';

import styles from './WalletConnected.module.scss';

export const WalletConnected: FC = observer(() => {
  const { user } = useMst();
  const { address, provider } = user;
  const shortenedUserAddress = useMemo(() => sliceString(address), [address]);

  const { disconnect } = useWalletConnectorContext();

  return (
    <div className={styles.wallet}>
      <div className={styles.walletContent}>
        <span className={styles.walletTitle}>Account</span>
        <span className={styles.walletDescription}>Connected with {provider}</span>
        <CopyToClipboard
          text={address}
          onCopy={() => toast.success('Your wallet address is copied!')}
        >
          <div className={styles.address}>
            <span className={styles.addressValue}>{shortenedUserAddress}</span>
            <Button className={styles.addressCopyBtn} classNameChildren={styles.addressCopyText}>
              Copy
            </Button>
          </div>
        </CopyToClipboard>
        <button className={styles.logout} type="button" onClick={disconnect}>
          <img className={styles.logoutIcon} src={logoutImg} alt="logout" />
          disconnect
        </button>
      </div>
    </div>
  );
});
