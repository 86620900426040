import { FC, PropsWithChildren } from 'react';
import cn from 'classnames';

import styles from './styles.module.scss';

export type Props = PropsWithChildren<{
  className?: string;
}>;

export const Container: FC<Props> = ({ className, children }) => (
  <div className={cn(styles.container, className)}>{children}</div>
);
