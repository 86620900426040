import { FC, useMemo } from 'react';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';

import { TALENTUM_TOKEN } from '@/appConstants';
import { Button, Tooltip } from '@/components';
import { useMst } from '@/store';
import { getBalanceAmount, numberFormatter } from '@/utils';

import styles from './BonusesLockedBalance.module.scss';

export type Props = {
  className?: string;
  onClick?: () => void;
};

export const BonusesLockedBalance: FC<Props> = observer(({ className, onClick }) => {
  const { crowdsale, crowdsaleToken } = useMst();

  const { crowdsaleTokenIndex } = crowdsale.getCrowdsaleTokenIndex();

  const lockedBalanceRaw =
    crowdsaleTokenIndex !== undefined ? crowdsale.amounts.toJSON()[crowdsaleTokenIndex] : undefined;
  const lockedBalance = lockedBalanceRaw
    ? getBalanceAmount(lockedBalanceRaw, crowdsaleToken.decimals)
    : undefined;
  const lockedBalanceToDisplay = useMemo(() => lockedBalance ?? 'Loading...', [lockedBalance]);
  const lockedBalanceShortenedToDisplay = useMemo(
    () => (lockedBalance ? numberFormatter(lockedBalance) : 'Loading...'),
    [lockedBalance],
  );

  return (
    <div className={cn(styles.container, className)}>
      <span className={styles.locked}>
        Your locked <span className="notranslate">{TALENTUM_TOKEN}</span> balance is
      </span>
      <Tooltip event="click" content={lockedBalanceToDisplay}>
        <span className={styles.balance}>
          {lockedBalanceShortenedToDisplay} {crowdsaleToken.symbol}
        </span>
      </Tooltip>

      <Button onClick={onClick}>Redeem</Button>
    </div>
  );
});
