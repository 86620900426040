import { FC, useState } from 'react';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';

import { BUY_FORM_NODE_ID } from '@/appConstants';
import { Container, Title } from '@/components';
import { useMst } from '@/store';

import {
  BuyFormConnectWalletToast,
  BuyFormCrypto,
  BuyFormFiat,
  BuyFormStats,
  BuyFormTabs,
  KindForm,
  Props,
} from '.';

import styles from './BuyForm.module.scss';

export const BuyForm: FC<Props> = observer(({ className }) => {
  const {
    user: { isConnectedWallet },
  } = useMst();
  const [kind, setKind] = useState<KindForm>('crypto');
  return (
    <section
      id={BUY_FORM_NODE_ID}
      className={cn(className, styles.section, {
        [styles['section_wallet-connected']]: isConnectedWallet,
      })}
    >
      <Container className={styles.container}>
        <Title className={styles.title}>
          Buy <span className={styles.titleGradient}>Talentum</span> now
        </Title>
        {!isConnectedWallet && <BuyFormConnectWalletToast />}
        <BuyFormTabs className={styles.tabs} value={kind} onChange={(value) => setKind(value)} />
        <div className={styles.content}>
          {kind === 'crypto' && <BuyFormCrypto />}
          {kind === 'fiat' && <BuyFormFiat />}
        </div>
      </Container>
      <BuyFormStats className={styles.stats} />
    </section>
  );
});
