import { FC, memo, useCallback, useState } from 'react';
import ReactSelect from 'react-select';
import cn from 'classnames';

import {
  Control,
  DropdownIndicator,
  IndicatorsContainer,
  IProps,
  Menu,
  OptionComponent,
  OPTIONS,
  SingleValue,
  ValueContainer,
} from '.';

import styles from './LanguageSelect.module.scss';

export const LanguageSelect: FC<IProps> = ({ className, value, isDisabled, onChange }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const handleMenuOpen = useCallback(() => setIsMenuOpen(true), []);
  const handleMenuClose = useCallback(() => setIsMenuOpen(false), []);
  return (
    <ReactSelect
      styles={{
        option: (provided) => ({
          ...provided,
          padding: '0 20px',
        }),
        dropdownIndicator: (provided) => ({
          ...provided,
          transform: isMenuOpen ? 'rotate(270deg)' : 'rotate(90deg)',
        }),
      }}
      className={cn(styles.select, className, 'notranslate', {
        [styles.select_disabled]: isDisabled,
      })}
      value={value}
      isMulti={false}
      isSearchable={false}
      options={OPTIONS}
      isDisabled={isDisabled}
      components={{
        ClearIndicator: () => null,
        IndicatorSeparator: () => null,
        Menu,
        Control,
        ValueContainer,
        SingleValue,
        Option: OptionComponent,
        DropdownIndicator,
        IndicatorsContainer,
      }}
      onMenuOpen={handleMenuOpen}
      onMenuClose={handleMenuClose}
      onChange={onChange}
    />
  );
};

export const MemoizedLanguageSelect = memo<IProps>(LanguageSelect);
