import { DOMAttributes, FC, HTMLAttributes } from 'react';
import cn from 'classnames';

import { TIcons } from '@/types';

export enum Icons {
  discord = 'discord',
  twitter = 'twitter',
  telegram = 'telegram',
  instagram = 'instagram',
  facebook = 'facebook',
  tiktok = 'tiktok',
  reddit = 'reddit',
  mail = 'mail',
  busd = 'busd',
  usdt = 'usdt',
  bnb = 'bnb',
  weth = 'weth',
  chevron = 'chevron',
  talentum = 'talentum',
  checkmark = 'checkmark',
  rejected = 'rejected',
}

export type Props = HTMLAttributes<HTMLElement> &
  DOMAttributes<HTMLElement> & {
    name: TIcons;
  };

export const Icon: FC<Props> = ({ className, name, ...props }) => (
  <i {...props} className={cn(`icon-${name}`, className)} />
);
