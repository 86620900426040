import { STAGE_DURATION_AS_DAYS, STAGES } from '@/appConstants';
import { rootStore } from '@/store';

import { dayjs } from '.';

export const getDaysPassedSinceStart = (startTimestamp: number) => {
  return dayjs().diff(startTimestamp * 1e3, 'days', true);
};

export const getDaysLeftBeforeEndTimestamp = (
  startTimestamp: number,
  endTimestamp: number,
): string | number => {
  const daysPassed = getDaysPassedSinceStart(startTimestamp);
  const timeLeft = dayjs
    .duration(dayjs.duration(endTimestamp - startTimestamp, 'second').asDays(), 'days')
    .subtract(daysPassed, 'days'); // 30 - 29 = 1; 30 - 28.9 = 1.1; 30 - 29.1 = 0.9
  const daysLeft = timeLeft.asDays();
  if (daysLeft >= 1) return Math.floor(daysLeft);
  if (daysLeft <= 0) return 0;
  return timeLeft.humanize(true);
};

export const getFormattedDateTimeLeft = () => {
  const { startTime } = rootStore.crowdsale;
  const daysLeft = getDaysLeftBeforeEndTimestamp(
    startTime,
    dayjs(startTime * 1e3)
      .add(dayjs.duration(STAGE_DURATION_AS_DAYS * STAGES, 'days'))
      .unix(),
  );
  const timeLeftPostfix = typeof daysLeft === 'number' ? 'days left' : '';

  return {
    daysLeft,
    timeLeftPostfix,
  };
};
