import BigNumber from 'bignumber.js';
import Web3 from 'web3';

export const MIN_UINT_256 = Web3.utils.hexToNumberString(
  '0x0000000000000000000000000000000000000000000000000000000000000000',
);
export const MAX_UINT_256 = Web3.utils.hexToNumberString(
  '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff',
);

// BN constants
export const BIG_ZERO = new BigNumber(0);
export const BIG_ONE = new BigNumber(1);
export const BIG_TEN = new BigNumber(10);
