import { onSnapshot, types } from 'mobx-state-tree';

import { DEFAULT_LANGUAGE } from '@/appConstants';
import { ChainsEnum, FetchStatus } from '@/types';
import { clog } from '@/utils';

import { ContractAddresses, Crowdsale, CrowdsaleToken, TokenPrices, User } from './models';

export const RootModel = types.model({
  user: User,
  contractAddresses: ContractAddresses,
  tokenPrices: TokenPrices,
  crowdsaleToken: CrowdsaleToken,
  crowdsale: Crowdsale,
});

export const rootStore = RootModel.create({
  user: {
    address: '',
    network: ChainsEnum.BinanceSmartChain,
    provider: '',
    isContractsExists: false,
    pageLang: DEFAULT_LANGUAGE,
  },
  contractAddresses: {
    token: '',
    crowdsale: '',
  },
  tokenPrices: {
    fetchStatus: FetchStatus.loading,
  },
  crowdsaleToken: {
    fetchStatus: FetchStatus.loading,
    decimals: -1,
    symbol: '',
    totalSupply: {
      raw: '',
      decimals: -1,
    },
  },
  crowdsale: {
    fetchStatus: FetchStatus.loading,
    tokenPrice: -1,

    stages: [],

    softcap: '',
    totalSold: '',
    currentStage: -1,
    hardcap: '',
    startTime: -1,

    paymentMethods: {},
    amounts: [],
  },
});

onSnapshot(rootStore, (snapshot) => {
  clog('Snapshot: ', snapshot);
});

export * from './context';
export * from './models';
export * from './hook';
