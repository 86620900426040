import { FC } from 'react';
import cn from 'classnames';

import { PropsContent } from '.';

import styles from './Tooltip.module.scss';

export const TooltipContent: FC<PropsContent> = ({ className, children }) => {
  return <div className={cn(styles.content, className)}>{children}</div>;
};
