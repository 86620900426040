import { ContractSendMethod } from 'web3-eth-contract';

import { ContractsWithDataEnum } from '@/types';

import { walletService } from '..';

interface ReadMethods {
  balanceOf: (address: string) => ContractSendMethod;
  decimals: () => ContractSendMethod;
  symbol: () => ContractSendMethod;
  totalSupply: () => ContractSendMethod;
}

interface WriteMethods {}

type TTLTMContractMethods = ReadMethods & WriteMethods;

export class TLTMContract {
  static get contract() {
    return walletService.connectWallet.Contract(ContractsWithDataEnum.TLTM);
  }

  static get contractMethods() {
    return TLTMContract.contract.methods as TTLTMContractMethods;
  }

  static balanceOf(address: string): Promise<string> {
    return TLTMContract.contractMethods.balanceOf(address).call();
  }

  static decimals(): Promise<string> {
    return TLTMContract.contractMethods.decimals().call();
  }

  static symbol(): Promise<string> {
    return TLTMContract.contractMethods.symbol().call();
  }

  static totalSupply(): Promise<string> {
    return TLTMContract.contractMethods.totalSupply().call();
  }
}
