import { FC, useMemo, useState } from 'react';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';

import { TALENTUM_TOKEN } from '@/appConstants';
import { leftBg, plumeImg, tubeImg, womanImg } from '@/assets/images';
import { Container, Title } from '@/components';
import { TLTMCrowdsale } from '@/services';
import { useMst } from '@/store';
import { STAKING_STAGE } from '@/types';
import { clog, getFormattedDateTimeLeft } from '@/utils';

import { StakingModal } from '..';

import { BonusesLockedBalance, BonusesProgressBar, BonusesRefundBlock } from '.';

import styles from './styles.module.scss';

export type Props = {
  className?: string;
};

const handlers = {
  async handleRedeem() {
    try {
      return await TLTMCrowdsale.redeem();
    } catch (err) {
      clog('error onRedeem', err);
      throw err;
    }
  },
  async handleRefund() {
    try {
      return await TLTMCrowdsale.refund();
    } catch (err) {
      clog('error onRefund', err);
      throw err;
    }
  },
};

export const Bonuses: FC<Props> = observer(({ className }) => {
  const { crowdsale, user } = useMst();
  const { daysLeft, timeLeftPostfix } = getFormattedDateTimeLeft();

  const { handleRedeem, handleRefund } = handlers;

  const [modalStageType, setModalStageType] = useState<STAKING_STAGE>(STAKING_STAGE.SENDING);
  const [isModalOpen, setModalOpen] = useState(false);
  const [sendAgainActionType, setSendAgainActionType] = useState<'redeem' | 'refund' | undefined>();

  const onRedeem = async () => {
    if (sendAgainActionType !== 'redeem') setSendAgainActionType('redeem');
    setModalOpen(true);
    setModalStageType(STAKING_STAGE.SENDING);
    try {
      await handleRedeem();
      setModalStageType(STAKING_STAGE.SUCCESS);
      crowdsale.fetchUserTokensAmounts();
    } catch (err) {
      setModalStageType(STAKING_STAGE.ERROR);
    }
  };

  const onRefund = async () => {
    if (sendAgainActionType !== 'refund') setSendAgainActionType('refund');
    setModalOpen(true);
    setModalStageType(STAKING_STAGE.SENDING);
    try {
      await handleRefund();
      setModalStageType(STAKING_STAGE.SUCCESS);
      crowdsale.fetchUserTokensAmounts();
      crowdsale.fetchTotalSold();
    } catch (err) {
      setModalStageType(STAKING_STAGE.ERROR);
    }
  };

  const infoText = useMemo(() => {
    if (!crowdsale.isAlreadyStarted || !crowdsale.isCrowdsaleIsOver)
      return `After you buy ${TALENTUM_TOKEN} tokens they are locked on the Contract. If Soft Cap isn\`t
    collected until the end of the token sale you will be able to refund your BNB, BUSD or
    USDT. If you want to withdraw your tokens now please press redeem button. NOTE: if you
    do that you won\`t be able to get a refund.`;

    if (crowdsale.isSoftcapCollected) return 'Our Pre-Sale has finished.';
    if (crowdsale.hasUserLockedCrowdsaleAmounts) {
      // eslint-disable-next-line max-len
      return `Our Pre-Sale has finished. Unfortunately, the Soft Cap wasn\`t collected. You can get a refund of BNB, BUSD or USDT by pressing "refund" button or withdraw your locked tokens by pressing "redeem".`;
    }
    return `Our Pre-Sale has finished. Unfortunately, the Soft Cap wasn\`t collected.`;
  }, [
    crowdsale.hasUserLockedCrowdsaleAmounts,
    crowdsale.isAlreadyStarted,
    crowdsale.isCrowdsaleIsOver,
    crowdsale.isSoftcapCollected,
  ]);

  return (
    <section className={cn(styles.section, className)}>
      <Title className={styles.title}>
        {daysLeft} {timeLeftPostfix}
      </Title>
      <Container className={styles.container}>
        {/*
          Let's say thanks to the designer for these chunks of images
        */}
        <img src={leftBg} className={styles.leftBg} alt="Bonuses by Talentum" />
        <img className={styles.womanImg} src={womanImg} alt="Bonuses by Talentum" />
        <img className={styles.tubeImg} src={tubeImg} alt="Bonuses by Talentum" />
        <img className={styles.plumeImg} src={plumeImg} alt="Bonuses by Talentum" />
        <div className={styles.content}>
          <BonusesProgressBar className={styles.progressBar} />
          <p className={styles.info}>{infoText}</p>
          <div className={styles.balancesBlock}>
            {user.isConnectedWallet && crowdsale.hasUserLockedCrowdsaleAmounts && (
              <BonusesLockedBalance className={styles.balance} onClick={onRedeem} />
            )}

            {/* STAGES[3] === COMPLETED and totalSold < SOFT_CAP */}
            {user.isConnectedWallet &&
              crowdsale.hasUserLockedCrowdsaleAmounts &&
              crowdsale.isCrowdsaleIsOver &&
              !crowdsale.isSoftcapCollected && (
                <BonusesRefundBlock className={styles.balance} onClick={onRefund} />
              )}
          </div>
        </div>
      </Container>
      <StakingModal
        isOpen={isModalOpen}
        stage={modalStageType}
        onAction={sendAgainActionType === 'redeem' ? onRedeem : onRefund}
        onClose={() => setModalOpen(false)}
      />
    </section>
  );
});
Bonuses.displayName = 'Bonuses';
