import { FC } from 'react';
import OnramperWidget from '@onramper/widget';
import cn from 'classnames';

import { ONRAMPER_API_KEY } from '@/appConstants';

import { Props } from '.';

import styles from './BuyFormFiat.module.scss';

// const wallets = {
//   ETH: { address: '0x22dE28cB00F1Be22B17DFFDecB8AFE88d088BB51', memo: 'cryptoTag' },
//   BNB: { address: 'bnbAddress', memo: 'cryptoTag' },
//   BUSD: { address: '0xeCe94E75d5bD84F181D09F3Be5d135e78c812aE8', memo: 'cryptoTag' },
//   USDT: { address: '0xc13d28b8870FF7162d8A78502762D97b255C670a', memo: 'cryptoTag' },
// };

export const BuyFormFiat: FC<Props> = ({ className }) => {
  return (
    <div className={cn(className, styles.form)}>
      <OnramperWidget
        defaultAmount={200}
        fontFamily="NotoSans"
        API_KEY={ONRAMPER_API_KEY}
        // wallets={wallets}
        // filters={{
        //   onlyCryptos: ['ETH'],
        // }}
      />
    </div>
  );
};
