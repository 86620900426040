import { toast } from 'react-toastify';
import { toJS } from 'mobx';
import { flow, types } from 'mobx-state-tree';

import { IGetTokensResponse, talentsLiveApi } from '@/services/api';
import { FetchStatus, TCurrency } from '@/types';
import { clogError } from '@/utils';

const TokenModel = types.model({
  symbol: types.string,
  address: types.string,
  decimals: types.number,
  price: types.string,
});

const modelArgs = {
  fetchStatus: types.enumeration<FetchStatus>('fetchStatus', Object.values(FetchStatus)),
  data: types.array(TokenModel),
};

/* eslint-disable no-param-reassign */
export const TokenPrices = types
  .model(modelArgs)
  .actions((self) => {
    const fetchTokenPrices = flow(function* fetchTokenPrices() {
      self.fetchStatus = FetchStatus.loading;
      try {
        const tokenPrices: IGetTokensResponse = yield talentsLiveApi.getTokens();
        self.fetchStatus = FetchStatus.success;
        self.data.clear();
        tokenPrices.forEach((item) => {
          self.data.push(item);
        });
      } catch (err) {
        clogError(err);
        self.fetchStatus = FetchStatus.error;
        toast.error("Error fetching current tokens' rates");
      }
    });

    return {
      fetchTokenPrices,
    };
  })
  .views((self) => {
    const getTokenDataBySymbol = (symbol: TCurrency) => {
      const arr: IGetTokensResponse = [];
      self.data
        .filter((token) => token.symbol.toUpperCase() === symbol.toUpperCase())
        .forEach((item) => arr.push(toJS(item)));
      return arr;
    };
    return {
      getTokenDataBySymbol,
      get isLoading() {
        return self.fetchStatus === FetchStatus.loading;
      },
      get isLoadingError() {
        return self.fetchStatus === FetchStatus.error;
      },
      get isLoadingSuccess() {
        return self.fetchStatus === FetchStatus.success;
      },
    };
  });
