import { FC } from 'react';
import {
  components,
  ControlProps,
  DropdownIndicatorProps,
  IndicatorsContainerProps,
  MenuProps,
  OptionProps,
  SingleValueProps,
  ValueContainerProps,
} from 'react-select';
import cn from 'classnames';

import { Icon } from '@/components';

import { IOption } from '.';

import styles from './LanguageSelect.module.scss';

export const Menu: FC<MenuProps<IOption, false>> = (props) => {
  return <components.Menu {...props} className={styles.menu} />;
};

export const Control: FC<ControlProps<IOption, false>> = (props) => {
  return <components.Control {...props} className={styles.control} />;
};

export const ValueContainer: FC<ValueContainerProps<IOption, false>> = (props) => {
  return <components.ValueContainer {...props} className={styles.valueContainer} />;
};

export const SingleValue: FC<SingleValueProps<IOption, false>> = ({ children, data, ...props }) => {
  return (
    <components.SingleValue {...props} data={data} className={styles.singleValue}>
      {children}
    </components.SingleValue>
  );
};

export const OptionComponent: FC<OptionProps<IOption, false>> = ({
  children,
  data,
  isSelected,
  ...props
}) => {
  return (
    <components.Option {...props} isSelected={isSelected} data={data} className={styles.option}>
      <div className={cn(styles.optionInner, { [styles.optionInner_selected]: isSelected })}>
        {children}
      </div>
    </components.Option>
  );
};

export const IndicatorsContainer: FC<IndicatorsContainerProps<IOption, false>> = (props) => {
  return <components.IndicatorsContainer {...props} className={styles.indicatorsContainer} />;
};

export const DropdownIndicator: FC<DropdownIndicatorProps<IOption, false>> = (props) => {
  return (
    <components.DropdownIndicator {...props} className={styles.dropdownIndicator}>
      <Icon name="chevron" />
    </components.DropdownIndicator>
  );
};
