import { STAGES, STAGES_PRICES } from '@/appConstants';
import { RootInstance } from '@/types';
import { numberFormatter, toBigNumber } from '@/utils';

type TStageStatus = 'Soon' | 'Active' | 'Completed';

type Stage = {
  id: number;
  status: TStageStatus;
  amount: string;
  price: string;
  isActive: boolean;
  isCompleted: boolean;
};

export const getStageStatusFlags = (status: TStageStatus) => {
  switch (status) {
    case 'Soon':
      return [false, false];
    case 'Active':
      return [true, false];
    case 'Completed':
    default:
      return [false, true];
  }
};

export const getStageData = (crowdsale: RootInstance['crowdsale']): Stage[] => {
  const source = crowdsale.stages.toJSON();
  if (source.length !== STAGES) return [];
  const statuses: TStageStatus[] = Array(STAGES)
    .fill(null)
    .map((_, index) => {
      if (crowdsale.currentStage === STAGES && crowdsale.isAlreadyStarted) return 'Completed';
      if (crowdsale.currentStage === STAGES && !crowdsale.isAlreadyStarted) return 'Soon';
      if (crowdsale.currentStage === index) return 'Active';
      if (index < crowdsale.currentStage) return 'Completed';
      return 'Soon'; // index > crowdsale.currentStage
    });
  const amounts = source.map(({ limit }) =>
    toBigNumber(limit)
      .multipliedBy(10 ** 6)
      .toFixed(),
  );
  const prices = STAGES_PRICES.map((price) => (+price / 10 ** 9).toString());
  return Array(STAGES)
    .fill(null)
    .map((_, index) => {
      const [isActive, isCompleted] = getStageStatusFlags(statuses[index]);
      return {
        id: index + 1,
        status: statuses[index],
        amount: numberFormatter(amounts[index]),
        price: prices[index],
        isActive,
        isCompleted,
      } as Stage;
    });
};
