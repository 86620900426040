import { ConnectWallet } from '@amfi/connect-wallet';
import Web3 from 'web3';
import { TransactionConfig } from 'web3-core';
import { AbiItem } from 'web3-utils';

import { chains, connectWallet as connectWalletConfig, contracts } from '@/config';
import { rootStore } from '@/store';
import { ChainsEnum, ContractsEnum, ContractsWithDataEnum, ProvidersEnum } from '@/types';
import { clogError } from '@/utils';

export class WalletService {
  public connectWallet: ConnectWallet;

  public walletAddress = '';

  constructor() {
    const chainId =
      chains[rootStore.user.network].provider.WalletConnect.provider?.rpc?.chainId ?? 0;
    this.connectWallet = new ConnectWallet(
      chains[rootStore.user.network].provider.WalletConnect.provider?.rpc?.rpc[chainId],
    );
  }

  public async initWalletConnect(
    chainName: ChainsEnum,
    providerName: ProvidersEnum,
  ): Promise<boolean> {
    const { provider, network, settings } = connectWalletConfig(chainName);

    try {
      const connectionResult = await this.connectWallet.connect(
        provider[providerName],
        network,
        settings,
      );
      if (typeof connectionResult === 'boolean') {
        return connectionResult;
      }
      throw connectionResult;
    } catch (err) {
      clogError('[WalletService.initWalletConnect]: ', err as Error | {});
      throw err;
    }
  }

  public logOut(): void {
    this.connectWallet.resetConect();
  }

  public Web3(): Web3 {
    return this.connectWallet.currentWeb3();
  }

  public setAccountAddress(address: string) {
    this.walletAddress = address;
  }

  public getAccount() {
    return this.connectWallet.getAccounts();
  }

  static getMethodInterface(abi: Array<AbiItem>, methodName: string): AbiItem {
    const [foundMethodInterface] = abi.filter((method) => method.name === methodName);
    return foundMethodInterface;
  }

  encodeFunctionCall(abi: AbiItem, data: Array<string>) {
    return this.Web3().eth.abi.encodeFunctionCall(abi, data);
  }

  createTransaction(
    method: string,
    data: Array<string>,
    contract: ContractsEnum | ContractsWithDataEnum,
    tx?: TransactionConfig,
    tokenAddress?: string,
    walletAddress?: string,
    value?: number | string,
  ) {
    const transactionMethod = WalletService.getMethodInterface(
      contracts.params[contract].abi,
      method,
    );

    let signature;
    if (transactionMethod) {
      signature = this.encodeFunctionCall(transactionMethod, data);
    }

    if (tx) {
      return this.sendTransaction({
        ...tx,
        from: walletAddress || this.walletAddress,
        data: signature,
      });
    }
    return this.sendTransaction({
      from: walletAddress || this.walletAddress,
      to: tokenAddress || this.connectWallet.Contract(contract).options.address,
      data: signature || '',
      value: value || '',
    });
  }

  sendTransaction(transactionConfig: TransactionConfig) {
    return this.Web3().eth.sendTransaction({
      ...transactionConfig,
      from: this.walletAddress,
    });
  }
}

export const walletService = new WalletService();
