import { useContext } from 'react';

import { RootInstance } from '@/types';

import { RootStoreContext } from './context';

export function useMst(): RootInstance {
  const store = useContext(RootStoreContext);
  if (store === null) {
    throw new Error('Store cannot be null, please add a context provider');
  }
  return store;
}
