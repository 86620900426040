import { chains } from '@/config';
import { walletService } from '@/services';
import { ChainsEnum, TTokens } from '@/types';

export const nativeSymbol = (chainName: ChainsEnum): string => {
  return chains[chainName].network.nativeCurrency!.symbol;
};

export const isNativeCurrency = (symbol: TTokens, chainName: ChainsEnum): boolean =>
  symbol.toUpperCase() === nativeSymbol(chainName);

/**
 * @param address user/token/contract address that is usually passed to CONTRACT.balanceOf(`address`)
 */
export const fetchNativeCurrencyBalance = (address: string) => {
  return walletService.connectWallet.getBalance(address);
};

export default { nativeSymbol, isNativeCurrency, fetchNativeCurrencyBalance };
