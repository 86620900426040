import { ChainsEnum, IChain, IConnectWallet } from '@/types';

import { BINANCE_CHAIN, IS_PRODUCTION } from './constants';
import { defaultProvider } from './providers';

export const chains: IChain = {
  [ChainsEnum.BinanceSmartChain]: {
    name: ChainsEnum.BinanceSmartChain,
    network: {
      chainID: BINANCE_CHAIN.chainId,
      chainName: IS_PRODUCTION ? 'Binance Smart Chain' : 'Binance Smart Chain Testnet',
      nativeCurrency: {
        name: 'BNB',
        symbol: 'BNB',
        decimals: 18,
      },
      rpc: BINANCE_CHAIN.rpc,
      blockExplorerUrl: BINANCE_CHAIN.blockExplorerUrl,
    },
    img: '',
    provider: {
      ...defaultProvider,
      WalletConnect: {
        ...defaultProvider.WalletConnect,
        provider: {
          ...defaultProvider.WalletConnect.provider,
          rpc: {
            ...defaultProvider.WalletConnect.provider?.rpc,
            chainId: BINANCE_CHAIN.chainId,
            rpc: {
              // ...defaultProvider.WalletConnect.provider?.rpc?.rpc,
              [BINANCE_CHAIN.chainId]: BINANCE_CHAIN.rpc,
            },
          },
        },
      },
    },
    explorer: BINANCE_CHAIN.blockExplorerUrl,
  },
};

export const connectWallet = (chainName: ChainsEnum): IConnectWallet => {
  const chain = chains[chainName];

  return {
    network: chain.network,
    provider: chain.provider,
    settings: { providerType: true },
  };
};
