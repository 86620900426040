import { MutableRefObject, useEffect } from 'react';
import { clearAllBodyScrollLocks, disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

export const useBodyScrollLock = <T extends HTMLElement | Element | null>(
  ref: MutableRefObject<T>,
  isDisabledBodyScroll: boolean,
) => {
  useEffect(() => {
    if (ref.current) {
      if (isDisabledBodyScroll) {
        disableBodyScroll(ref.current);
      } else {
        enableBodyScroll(ref.current);
      }
    }

    return () => clearAllBodyScrollLocks();
  }, [isDisabledBodyScroll, ref]);
};
