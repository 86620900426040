import { FC } from 'react';
import cn from 'classnames';

import { SOCIAL_LINKS } from '@/appConstants';
import { Icon } from '@/components';

import { LinkProps, Props } from '.';

import styles from './styles.module.scss';

export const SocialLink: FC<LinkProps> = ({ className, icon, to }) => {
  return (
    <li className={cn(className)}>
      <a className={styles.link} href={to} target="_blank" rel="noreferrer">
        <Icon name={icon} />
      </a>
    </li>
  );
};

export const SocialLinks: FC<Props> = ({ className, children }) => {
  return <ul className={cn(className)}>{children(SOCIAL_LINKS)}</ul>;
};
