import { IProviders, ProvidersEnum } from '@/types';

import { BINANCE_CHAIN } from './constants';

export const defaultProvider: IProviders = {
  MetaMask: { name: ProvidersEnum.MetaMask, img: '' },
  WalletConnect: {
    img: '',
    name: ProvidersEnum.WalletConnect,
    useProvider: 'rpc',
    provider: {
      rpc: {
        rpc: {
          [BINANCE_CHAIN.chainId]: BINANCE_CHAIN.rpc,
        },
        chainId: BINANCE_CHAIN.chainId,
      },
    },
  },
};
