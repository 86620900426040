import { TALENTUM_TOKEN } from '@/appConstants';
import { bgNet, singingMan } from '@/assets/images';
import { Container } from '@/components';

import styles from './styles.module.scss';

export const ProjectInfo = () => {
  return (
    <section className={styles.section}>
      <Container className={styles.container}>
        <img src={bgNet} className={styles.bgNet} alt="About Talentum" />
        <img className={styles.image} src={singingMan} alt="About Talentum" />
        <div className={styles.content}>
          <h3 className={styles.title}>Project info</h3>
          <p className={styles.info}>
            Deflationary {TALENTUM_TOKEN} Token ({TALENTUM_TOKEN}) taps into the latent power of
            human psychology to promote environmental sustainability.
            <br />
            <br />
            Global interconnectivity has given rise to some of the most positively disruptive
            technology in human history but is causing environmental degradation. Crypto mining is
            now a trend that is being used to incentivize the use of sustainable energy.
          </p>
        </div>
      </Container>
    </section>
  );
};
