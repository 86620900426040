import { Icons } from '@/components/Icon';

export type TNullable<T> = T | null;
export type TOptionable<T> = T | undefined;

export enum WalletConnectionPlatform {
  Metamask = 'Metamask',
  WalletConnect = 'Wallet Connect',
}

export enum Currency {
  usdt = 'usdt',
  bnb = 'bnb',
  busd = 'busd',
  weth = 'weth',
}

export type TCurrency = keyof typeof Currency;

export type ExternalLink = {
  label: string;
  to: string;
};

export enum FetchStatus {
  loading = 'loading',
  success = 'success',
  error = 'error',
}

export enum STAKING_STAGE {
  INIT = 'INIT',
  APPROVING = 'APPROVING',
  APPROVED = 'APPROVED',
  APPROVE_ERROR = 'APPROVE_ERROR',
  SENDING = 'SENDING',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

export type TIcons = keyof typeof Icons;

export type Link = {
  to: string;
  icon: TIcons;
};

export type TLanguageCode = 'en' | 'es' | 'ru' | 'zh-TW' | 'de' | 'fr' | 'ar';
export interface ILanguageCodes extends Record<TLanguageCode, string> {}
