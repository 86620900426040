import { useEffect } from 'react';

export function useResize(handler: ((e: UIEvent) => void) | null) {
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (handler) {
      window.addEventListener('resize', handler);
      return () => window.removeEventListener('resize', handler);
    }
  }, [handler]);
}
