import { FC } from 'react';
import cn from 'classnames';

import { DEVELOPER_LINK } from '@/appConstants';
import { developerLogo } from '@/assets/images';

import styles from './DeveloperLogo.module.scss';

export type Props = {
  className?: string;
};

export const DeveloperLogo: FC<Props> = ({ className }) => {
  return (
    <div className={cn(className)}>
      <span className={styles.poweredBy}>Powered by</span>
      <a href={DEVELOPER_LINK} target="_blank" rel="noreferrer">
        <img src={developerLogo} alt="Powered by Rock'n'Block" />
      </a>
    </div>
  );
};
