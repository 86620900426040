import { flow, getRoot, types } from 'mobx-state-tree';

import { chains } from '@/config';
import { ChainsEnum, ProvidersEnum, RootInstance, TLanguageCode } from '@/types';
import { TTokens } from '@/types/tokens';
import { balanceOf, clog, fetchNativeCurrencyBalance, isNativeCurrency } from '@/utils';

import { BigNumberValue } from '.';

const Balance = types.model({
  tokenSymbol: types.string,
  balance: BigNumberValue,
});

/* eslint-disable no-param-reassign */
export const User = types
  .model({
    address: types.string,
    network: types.enumeration<ChainsEnum>('network', Object.values(ChainsEnum)),
    provider: types.enumeration<ProvidersEnum | ''>('provider', [
      '',
      ...Object.values(ProvidersEnum),
    ]),
    isContractsExists: types.boolean,

    balances: types.map(Balance),
    pageLang: types.string,
  })
  .actions((self) => {
    const setAddress = (addr: string) => {
      self.address = addr;
    };
    const setNetwork = (network: ChainsEnum) => {
      self.network = network;
    };
    const setProvider = (provider: ProvidersEnum) => {
      self.provider = provider;
    };
    const disconnect = () => {
      self.address = '';
      // self.network = chainsEnum.Ethereum;
      self.provider = '';
    };
    const initializeContracts = () => {
      self.isContractsExists = true;
    };

    const setBalance = (tokenSymbol: TTokens, rawBalance: string, decimals: number) => {
      const uppercasedTokenSymbol = tokenSymbol.toUpperCase();
      self.balances.set(uppercasedTokenSymbol, {
        tokenSymbol: uppercasedTokenSymbol,
        balance: {
          raw: rawBalance,
          decimals,
        },
      });
    };

    /**
     * @param address user/token/contract address that is usually passed to CONTRACT.balanceOf(address)
     */
    const fetchBalanceOf = flow(function* fetchBalanceOf(
      tokenSymbol: TTokens,
      address: string,
    ): Generator<Promise<void> | Promise<string | number>, void, any> {
      const store = getRoot<RootInstance>(self);
      if (tokenSymbol === 'TLTM') {
        yield store.crowdsaleToken.fetchUserBalance(address);
        return undefined;
      }

      if (isNativeCurrency(tokenSymbol, self.network)) {
        try {
          const fetchedBalance: string | number = yield fetchNativeCurrencyBalance(address);
          store.user.setBalance(
            tokenSymbol,
            fetchedBalance.toString(),
            chains[self.network].network.nativeCurrency!.decimals,
          );
        } catch (err) {
          clog('[Store/User fetchNativeCurrencyBalance]', err);
        }
        return undefined;
      }

      try {
        const [tokenData] = store.tokenPrices.getTokenDataBySymbol(tokenSymbol);
        const fetchedBalance: string = yield balanceOf({
          contractAddress: tokenData.address,
        });
        store.user.setBalance(tokenSymbol, fetchedBalance, tokenData.decimals);
      } catch (err) {
        clog('[Store/User balanceOf]', err);
      }
      return undefined;
    });

    const setPageLanguage = (selectedPageLanguage: TLanguageCode) => {
      localStorage.setItem('language', selectedPageLanguage);
      self.pageLang = selectedPageLanguage;
    };
    return {
      setAddress,
      setNetwork,
      setProvider,
      disconnect,
      initializeContracts,
      setBalance,
      fetchBalanceOf,
      setPageLanguage,
    };
  })
  .views((self) => {
    const getBalance = (tokenSymbol: TTokens) => {
      const uppercasedTokenSymbol = tokenSymbol.toUpperCase();
      return self.balances.get(uppercasedTokenSymbol);
    };
    return {
      get isConnectedWallet() {
        return Boolean(self.address);
      },
      getBalance,
      get pageLanguage() {
        return self.pageLang as TLanguageCode;
      },
    };
  });
