import { FC, useMemo } from 'react';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';

import { TALENTUM_TOKEN } from '@/appConstants';
import { useMst } from '@/store';
import { numberFormatter, toBigNumber } from '@/utils';

import styles from './BonusesProgressBar.module.scss';

export type Props = {
  className?: string;
};

// eslint-disable-next-line react/require-default-props
const Triangle: FC<{ className?: string }> = ({ className }) => {
  return (
    <svg
      width="11"
      height="9"
      viewBox="0 0 11 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={cn(className)}
    >
      <path d="M5.5 9L0.73686 0.750001L10.2631 0.75L5.5 9Z" fill="url(#paint0_linear_64_2698)" />
      <defs>
        <linearGradient
          id="paint0_linear_64_2698"
          x1="11"
          y1="3.5"
          x2="-3.98869e-07"
          y2="3.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFA439" />
          <stop offset="1" stopColor="#FF2E3C" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export const BonusesProgressBar: FC<Props> = observer(({ className }) => {
  const { crowdsale } = useMst();
  const tokensSold = crowdsale.getTotalSold;
  const tokensSoldFormattedToDisplay = useMemo(
    () => (tokensSold !== 'NaN' ? numberFormatter(tokensSold) : 'Loading...'),
    [tokensSold],
  );
  const tokensSoftCap = crowdsale.getSoftcap;
  const tokensSoftCapFormatted =
    tokensSoftCap === 'NaN' ? 'Loading...' : numberFormatter(tokensSoftCap);
  const tokensHardCapFormatted = numberFormatter(crowdsale.getHardcap);

  const softCapAsProgressValuesPercents = toBigNumber(tokensSoftCap)
    .dividedBy(crowdsale.getHardcap)
    .multipliedBy(100)
    .toFixed();

  const progressValueAsPercents = toBigNumber(tokensSold)
    .dividedBy(crowdsale.getHardcap)
    .multipliedBy(100)
    .toFixed();
  return (
    <div className={cn(styles.container, className)}>
      <div
        style={{
          left: `${softCapAsProgressValuesPercents}%`,
        }}
        className={styles.softCapWrap}
      >
        <div className={styles.softCap}>
          <span className={styles.title}>Soft cap</span>
          <span className={styles.description}>
            {tokensSoftCapFormatted} <span className="notranslate">{TALENTUM_TOKEN}</span>
          </span>
          <Triangle className={styles.softCapTriangle} />
        </div>
      </div>

      <div className={styles.progressBarWrap}>
        <div className={styles.progressBarCap}>
          <span className={styles.title}>Sold</span>
          <span className={styles.description}>
            {tokensSoldFormattedToDisplay} <span className="notranslate">{TALENTUM_TOKEN}</span>
          </span>
        </div>
        <div className={styles.progressBar}>
          <span
            style={{ width: `${progressValueAsPercents}%` }}
            className={styles.progressBarLine}
          />
        </div>
      </div>

      <div className={styles.hardCapWrap}>
        <div className={styles.hardCap}>
          <Triangle className={styles.hardCapTriangle} />
          <span className={styles.title}>Hard Cap</span>
          <span className={styles.description}>
            {tokensHardCapFormatted} <span className="notranslate">{TALENTUM_TOKEN}</span>
          </span>
        </div>
      </div>
    </div>
  );
});
