import { StrictMode } from 'react';
import { hydrate, render } from 'react-dom';

import { App } from '@/containers';

import '@/store/localStorageWrapper';

import './index.scss';

const rootElement = document.getElementById('root');

const rootComponent = (
  <StrictMode>
    <App />
  </StrictMode>
);

if (rootElement?.hasChildNodes()) {
  hydrate(rootComponent, rootElement);
} else {
  render(rootComponent, rootElement);
}
