import { FC } from 'react';
import cn from 'classnames';

import { features } from '@/appConstants';
import { bouncyMan } from '@/assets/images';
import { Container } from '@/components';

import { Props } from '.';

import styles from './Features.module.scss';

export const Features: FC<Props> = ({ className }) => {
  return (
    <section className={cn(styles.section, className)}>
      <Container className={styles.container}>
        <img src={bouncyMan} className={styles.bouncyMan} alt="Talentum" />
        <div className={styles.titleBlock}>
          <h3 className={styles.title}>
            <span className={styles.titleText}>Token key</span>
            <span className={styles.titleLabel}>
              <span className={styles.titleLabelText}>Features</span>
            </span>
          </h3>
          <p className={styles.description}>
            <span>10%</span> <span>distributed from each transaction</span>
          </p>
        </div>

        <div className={styles.listContainer}>
          <ul className={styles.list}>
            {features.map((feature) => (
              <li key={feature.name} className={styles.item}>
                <span className={styles.percent}>{`${feature.percent} %`}</span>
                <span className={styles.name}>{feature.name}</span>
              </li>
            ))}
          </ul>
        </div>
      </Container>
    </section>
  );
};
