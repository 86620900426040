import { FC, Fragment } from 'react';
import cn from 'classnames';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';

import { Button, Tooltip } from '@/components';
import { useMst } from '@/store';
import { getBalanceAmount, numberFormatter } from '@/utils';

import styles from './BonusesRefundBlock.module.scss';

export type Props = {
  className?: string;
  onClick?: () => void;
};

interface IBonusesRefundBlockBalance {
  name: string;
  value: string;
  valueFormatted: string;
}

export const BonusesRefundBlock: FC<Props> = observer(({ className, onClick }) => {
  const { crowdsale, tokenPrices } = useMst();

  const { crowdsaleTokenIndex } = crowdsale.getCrowdsaleTokenIndex();

  const getBalances = () => {
    const balances: IBonusesRefundBlockBalance[] = [];
    toJS(tokenPrices.data).forEach(({ symbol, decimals }, index) => {
      if (crowdsaleTokenIndex === index) return;
      const amount = toJS(crowdsale.amounts)[index];

      if (+amount) {
        const value = getBalanceAmount(amount, decimals);
        balances.push({
          name: symbol,
          value,
          valueFormatted: numberFormatter(value),
        });
      }
    });
    return balances;
  };

  const balances = getBalances().map(({ name, value, valueFormatted }, index, arr) => (
    <Fragment key={value + name}>
      <Tooltip event="click" content={`${value} ${name}`}>
        <span>
          {valueFormatted} {name}
        </span>
      </Tooltip>
      {index !== arr.length - 1 && ', '}
    </Fragment>
  ));

  return (
    <div className={cn(styles.container, className)}>
      <span className={styles.locked}>You Will Get</span>
      <span className={styles.balance}>{balances}</span>
      <Button onClick={onClick}>Refund</Button>
    </div>
  );
});
