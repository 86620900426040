import { ChainsIdEnum } from '@/types';

export const IS_PRODUCTION = true;
export const SHOW_LOGS = IS_PRODUCTION;

export const BINANCE_CHAIN = {
  chainId: IS_PRODUCTION
    ? ChainsIdEnum.BinanceSmartChainMainnet
    : ChainsIdEnum.BinanceSmartChainTestnet,
  rpc: IS_PRODUCTION
    ? 'https://bsc-dataseed.binance.org'
    : 'https://data-seed-prebsc-1-s1.binance.org:8545',
  blockExplorerUrl: IS_PRODUCTION ? 'https://bscscan.com' : 'https://testnet.bscscan.com',
};
