import { ILanguageCodes, TLanguageCode } from '@/types';

import { IOption } from '.';

export const LANGUAGE_CODES: ILanguageCodes = {
  'en': 'English',
  'es': 'Spanish',
  'ru': 'Russian',
  'zh-TW': 'Chinese',
  'de': 'German',
  'fr': 'French',
  'ar': 'Arabic',
};

export const OPTIONS: IOption[] = Object.entries(LANGUAGE_CODES).map(
  ([key, value]) =>
    ({
      value: key,
      label: value,
    } as IOption),
);

export const OPTIONS_AS_MAP: Record<TLanguageCode, IOption> = OPTIONS.reduce((acc, cur) => {
  acc[cur.value] = cur;
  return acc;
}, {} as Record<TLanguageCode, IOption>);
