/* eslint-disable react-hooks/exhaustive-deps */
import { cloneElement, DOMAttributes, useCallback, useEffect, useRef, useState } from 'react';
import cn from 'classnames';

import { useClickOutside, useResize, useScroll } from '@/hooks';

import { Event, getXPosition, getYPosition, Props, TooltipContent } from '.';

import styles from './Tooltip.module.scss';

export function useTooltip({
  children,
  event = 'hover',
  offsetX = 0,
  offsetY = 10,
  content,
  className,
  positionY = 'bottom',
  onClose,
}: Props) {
  const parentRef = useRef<HTMLElement | null>(null);
  const contentRef = useRef<HTMLDivElement | null>(null);
  const [isOpen, setIsOpen] = useState(false);

  const setPosition = useCallback(() => {
    if (parentRef.current === null || contentRef.current === null) return;

    contentRef.current.style.display = 'block';
    const verticalOffset = getYPosition(parentRef.current, contentRef.current, positionY, offsetY);
    const horizontalOffset = getXPosition(parentRef.current, contentRef.current, offsetX);

    contentRef.current.style.top = `${verticalOffset}px`;
    contentRef.current.style.left = `${horizontalOffset}px`;
  }, [positionY, offsetX, offsetY]);

  const handleOpen = useCallback(() => {
    if (parentRef.current !== null && contentRef.current !== null) {
      setIsOpen(true);
    }
  }, [offsetX]);

  useResize(isOpen ? setPosition : null);
  useScroll(isOpen ? setPosition : null);

  const handleClose = useCallback(() => {
    if (contentRef.current !== null) {
      if (onClose) onClose();
      setIsOpen(false);

      contentRef.current.style.display = 'none';
    }
  }, []);

  const handleClick = useCallback(() => {
    if (!isOpen) {
      handleOpen();
    } else {
      handleClose();
    }
  }, [isOpen]);

  useClickOutside([contentRef, parentRef], () => {
    /**
     * Click outside available only for event === 'click'
     */
    if (event === 'click') {
      handleClose();
    }
  });

  const eventsToHandlersMapper: Record<Event, DOMAttributes<HTMLElement>> = {
    hover: {
      onMouseOver: handleOpen,
      onMouseOut: handleClose,
    },
    click: {
      onClick: handleClick,
    },
  };

  useEffect(() => {
    if (isOpen) {
      setPosition();
    }
  }, [isOpen]);

  const Tooltip = (
    <>
      {cloneElement(children, {
        ...children.props,
        ...eventsToHandlersMapper[event],
        // eslint-disable-next-line no-return-assign
        ref: (el: HTMLElement | null) => (parentRef.current = el),
      })}
      <div ref={contentRef} className={styles.wrapper}>
        {isOpen && (
          <TooltipContent className={cn(styles.body, className)}>{content}</TooltipContent>
        )}
      </div>
    </>
  );

  return {
    parentRef,
    contentRef,
    isOpen,
    handleOpen,
    handleClose,
    handleClick,
    Tooltip,
  };
}
