import { Dispatch, FC, FormEvent, SetStateAction, useCallback, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import BigNumber from 'bignumber.js';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';

import { TALENTUM_TOKEN } from '@/appConstants';
import { Icon, InputAmount, TargetButton, Tooltip } from '@/components';
import { StakingModal } from '@/containers';
import { useApprove } from '@/hooks';
import { TLTMCrowdsale, walletService } from '@/services';
import { talentsLiveApi } from '@/services/api';
import { useMst } from '@/store';
import {
  ContractsWithDataEnum,
  Currency,
  RootInstance,
  STAKING_STAGE,
  TCurrency,
  TNullable,
} from '@/types';
import {
  clog,
  getBalanceAmount,
  getDecimalAmount,
  getDecimalAmountBN,
  inputNumbersWithDecimalsRegExp,
  numberFormatter,
  toBigNumber,
} from '@/utils';
import { debounce } from '@/utils/debounce';
import nativeCurrency from '@/utils/nativeCurrency';

import { Amount, Props } from '.';

import styles from './BuyFormCrypto.module.scss';

const TalentumLabel = () => {
  return (
    <div className={styles.talentumLabel}>
      <span className={styles.talentumIcon}>
        <Icon name="talentum" />
      </span>
      Talentum
    </div>
  );
};

const debouncedFromValueChanged = debounce(
  async (
    amount: string,
    currency: TNullable<TCurrency>,
    store: RootInstance,
    setMinAllowanceInWei: Dispatch<SetStateAction<string>>,
    setFrom: Dispatch<SetStateAction<Amount>>,
    evalToValueByFrom: (fromAmount: string, currency: TNullable<TCurrency>) => string | undefined,
    setTo: Dispatch<SetStateAction<string>>,
    evalFromValueByTo: (toAmount: string) => string | undefined,
  ) => {
    const { tokenPrices, crowdsale, crowdsaleToken, user } = store;
    if (!amount) return;
    if (!tokenPrices.isLoadingSuccess) return;
    if (!currency) return;

    await crowdsale.fetchCurrentStageSoldAmount();
    await user.fetchBalanceOf(currency, user.address);

    if (!crowdsale.currentStageSoldAmount) return;
    const availableAmountToBeSoldAsToAmount = getDecimalAmountBN(
      crowdsale.currentStageLimit,
      crowdsaleToken.decimals,
    ).minus(crowdsale.currentStageSoldAmount); // as TLTM
    if (availableAmountToBeSoldAsToAmount.isZero()) {
      toast.success('Sold out. Wait till the next stage starts.');
      return;
    }
    const [fromToken] = tokenPrices.getTokenDataBySymbol(currency);
    const decimalAmount = getDecimalAmount(amount, fromToken.decimals);
    const avaliableAmountToBeSoldAsFromAmount = getDecimalAmountBN(
      evalFromValueByTo(
        getBalanceAmount(availableAmountToBeSoldAsToAmount, crowdsaleToken.decimals),
      ) || '0',
      fromToken.decimals,
    );

    // validation conditions
    // AMOUNT: 1 | BALANCE: 2  | LEFT: 1.5 = 1
    // AMOUNT: 1 | BALANCE: 1.4 | LEFT: 1.5 = 1
    // AMOUNT: 2 | BALANCE: 1.4 | LEFT: 1.5 = 1.4
    // AMOUNT: 2 | BALANCE: 1.6 | LEFT: 1.1 = 1.1

    // 1. Find minimum between restrictions (BALANCE and LEFT)
    // 2. Compare AMOUNT and MIN. AMOUNT must be less or equal than MIN
    const restrictions = [
      toBigNumber(user.getBalance(currency)?.balance.raw),
      avaliableAmountToBeSoldAsFromAmount,
    ];
    const minimumBetweenRestrictions = BigNumber.minimum(...restrictions);

    if (minimumBetweenRestrictions.isLessThan(decimalAmount)) {
      setMinAllowanceInWei(minimumBetweenRestrictions.toFixed());
      setFrom({
        amount: getBalanceAmount(minimumBetweenRestrictions, fromToken.decimals),
        currency,
      });
      const toAmount = evalToValueByFrom(
        getBalanceAmount(minimumBetweenRestrictions, fromToken.decimals),
        currency,
      );
      if (toAmount) {
        setTo(toAmount);
      }
    } else {
      setMinAllowanceInWei(decimalAmount);
    }
  },
  500,
  false,
);

export const BuyFormCrypto: FC<Props> = observer(({ className }) => {
  const store = useMst();
  const { tokenPrices, user, crowdsale, crowdsaleToken } = store;
  const [from, setFrom] = useState<Amount>({ amount: '', currency: 'usdt' });
  const [to, setTo] = useState<string>('');
  const [modalStageType, setModalStageType] = useState<STAKING_STAGE>(STAKING_STAGE.INIT);
  const [isModalOpen, setModalOpen] = useState(false);
  const { hasAllowance, approve, setMinAllowanceInWei } = useApprove(
    from.currency as Currency,
    ContractsWithDataEnum.TLTMCrowdsale,
  );

  const handleSubmit = useCallback((e: FormEvent<HTMLFormElement>) => e.preventDefault(), []);
  const clearInputs = useCallback(() => {
    setFrom({
      ...from,
      amount: '0',
    });
    setTo('0');
  }, [from]);

  const isCrowdsaleActive = useMemo(
    () => crowdsale.isAlreadyStarted && !crowdsale.isCrowdsaleIsOver,
    [crowdsale.isAlreadyStarted, crowdsale.isCrowdsaleIsOver],
  );
  const ableToBuy = useMemo(() => {
    const inputFieldNotEmpty = +from.amount > 0;
    const isCrowdsaleActive = crowdsale.isAlreadyStarted && !crowdsale.isCrowdsaleIsOver;
    return inputFieldNotEmpty && user.isConnectedWallet && isCrowdsaleActive;
  }, [
    crowdsale.isAlreadyStarted,
    crowdsale.isCrowdsaleIsOver,
    from.amount,
    user.isConnectedWallet,
  ]);

  const userBalance = user.getBalance('TLTM')?.balance.parsed;
  const userBalanceToDisplay = useMemo(() => userBalance ?? 'Loading...', [userBalance]);
  const userBalanceShortenedToDisplay = useMemo(
    () => (userBalance ? numberFormatter(userBalance) : 'Loading...'),
    [userBalance],
  );
  const onBuy = useCallback(async () => {
    if (from.currency === null) return;

    const [tokenData] = tokenPrices.getTokenDataBySymbol(from.currency);
    const { address, decimals } = tokenData;
    // get decimal amount and Math.floor()
    const amountToBuy = getDecimalAmountBN(from.amount, decimals).toFixed(0, 1);
    let signature;
    try {
      signature = await talentsLiveApi.createSignature(address, amountToBuy);
    } catch (err) {
      clog('[BuyFormCrypto/onBuy/createSignature]: error ', err);
    }

    if (signature) {
      setModalStageType(STAKING_STAGE.SENDING);
      setModalOpen(true);
      try {
        const isNativeCurrency = nativeCurrency.isNativeCurrency(from.currency, user.network);
        if (isNativeCurrency) {
          await walletService.createTransaction(
            'buy',
            [
              signature.token_address,
              signature.amount_to_pay,
              signature.amount_to_receive,
              signature.signature_expiration_timestamp,
              signature.signature,
            ],
            ContractsWithDataEnum.TLTMCrowdsale,
            undefined,
            undefined,
            undefined,
            amountToBuy,
          );
        } else {
          await TLTMCrowdsale.buy(
            signature.token_address,
            signature.amount_to_pay,
            signature.amount_to_receive,
            signature.signature_expiration_timestamp,
            signature.signature,
          );
        }

        setModalStageType(STAKING_STAGE.SUCCESS);
        clearInputs();
        crowdsale.fetchUserTokensAmounts();
        crowdsale.fetchTotalSold();
      } catch (err) {
        clog('[BuyFormCrypto/onBuy/TLTMCrowdsale.buy]: error ', err);
        setModalStageType(STAKING_STAGE.ERROR);
      }
    }
  }, [clearInputs, crowdsale, from.amount, from.currency, tokenPrices, user.network]);

  const handleApprove = useCallback(async () => {
    setModalOpen(true);
    setModalStageType(STAKING_STAGE.APPROVING);
    try {
      await approve();
      setModalStageType(STAKING_STAGE.APPROVED);
      setTimeout(onBuy, 2000);
    } catch (err) {
      setModalStageType(STAKING_STAGE.APPROVE_ERROR);
    }
  }, [approve, onBuy]);

  const buyHandler = useMemo(() => {
    if (!ableToBuy) return undefined;
    if (!hasAllowance) return handleApprove;
    return onBuy;
  }, [ableToBuy, handleApprove, hasAllowance, onBuy]);

  const evalToValueByFrom = (fromAmount: string, currency: TNullable<TCurrency>) => {
    if (!tokenPrices.isLoadingSuccess) return undefined;
    if (!currency) return undefined;
    const [fromToken] = tokenPrices.getTokenDataBySymbol(currency);
    const toAmount = toBigNumber(fromAmount)
      .multipliedBy(fromToken.price)
      .dividedBy(crowdsale.tokenPrice)
      .toFixed();
    return toAmount;
  };
  const evalFromValueByTo = (toAmount: string) => {
    if (!tokenPrices.isLoadingSuccess) return undefined;
    if (!from.currency) return undefined;
    const [fromToken] = tokenPrices.getTokenDataBySymbol(from.currency);
    const fromAmount = toBigNumber(toAmount)
      .multipliedBy(crowdsale.tokenPrice)
      .dividedBy(fromToken.price)
      .toFixed();
    return fromAmount;
  };

  return (
    <form className={cn(className)} onSubmit={handleSubmit}>
      <StakingModal
        isOpen={isModalOpen}
        stage={modalStageType}
        onAction={buyHandler}
        onClose={() => setModalOpen(false)}
      />
      <InputAmount
        className={styles.formGroup}
        label="Spend"
        disabled={!isCrowdsaleActive}
        currency={from.currency}
        value={from.amount}
        onValueChange={(amount, currency) => {
          if (!isCrowdsaleActive) return;
          if (!amount) {
            setFrom({ amount: '', currency });
            const toAmount = evalToValueByFrom('0', currency);
            if (toAmount) {
              setTo(toAmount);
            }
            return;
          }
          const isValid = amount.match(inputNumbersWithDecimalsRegExp);
          if (!isValid) return;
          setFrom({
            amount,
            currency,
          });
          const toAmount = evalToValueByFrom(amount, currency);
          if (toAmount) {
            setTo(toAmount);
          }
          debouncedFromValueChanged(
            amount,
            currency,
            store,
            setMinAllowanceInWei,
            setFrom,
            evalToValueByFrom,
            setTo,
            evalFromValueByTo,
          );
        }}
      />
      <InputAmount
        className={styles.formGroup}
        label={`You will receive ${TALENTUM_TOKEN}`}
        disabled={!isCrowdsaleActive}
        currency={null}
        customCurrency={<TalentumLabel />}
        value={to}
        onValueChange={(value) => {
          if (!isCrowdsaleActive) return;
          if (!value) {
            setTo('');
            const fromAmount = evalFromValueByTo('0');
            if (fromAmount) {
              setFrom({
                ...from,
                amount: fromAmount,
              });
            }
            return;
          }
          const isValid = value.match(inputNumbersWithDecimalsRegExp);
          if (!isValid) return;
          setTo(value);
          const fromAmount = evalFromValueByTo(value);
          if (fromAmount) {
            setFrom({
              ...from,
              amount: fromAmount,
            });
          }
          debouncedFromValueChanged(
            fromAmount,
            from.currency,
            store,
            setMinAllowanceInWei,
            setFrom,
            evalToValueByFrom,
            setTo,
            evalFromValueByTo,
          );
        }}
      />
      {user.isConnectedWallet && (
        <div className={styles.balance}>
          <span>Your {TALENTUM_TOKEN} balance</span>

          <Tooltip event="click" content={userBalanceToDisplay}>
            <span>
              {userBalanceShortenedToDisplay} {crowdsaleToken.symbol}
            </span>
          </Tooltip>
        </div>
      )}

      <p className={styles.description}>
        You buy {TALENTUM_TOKEN} Tokens by sending {from.currency?.toUpperCase()} to the contract
      </p>
      <TargetButton
        className={styles.submit}
        type="submit"
        disabled={!ableToBuy}
        onClick={buyHandler}
      >
        Buy
      </TargetButton>
    </form>
  );
});
