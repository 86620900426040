import { useEffect, useMemo, useRef, useState } from 'react';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';

import { Burger, Container, Logo, MemoizedLanguageSelect, Modal, Tooltip } from '@/components';
import { OPTIONS_AS_MAP } from '@/components/LanguageSelect';
import { useBodyScrollLock, useGoogleTranslate, useResize } from '@/hooks';
import { useMst } from '@/store';
import { sliceString } from '@/utils';

import { WalletConnected } from './WalletConnected';
import { HeaderConnectionMenu, HeaderExternalLinks, HeaderMobileMenu } from '.';

import styles from './Header.module.scss';

export const Header = observer(() => {
  const { user } = useMst();
  const { handleChangeLanguage, isGoogleTranslateInitialized } = useGoogleTranslate();
  const shortenedUserAddress = useMemo(() => sliceString(user.address), [user.address]);

  const [isVisibleWalletConnect, setIsVisibleWalletConnect] = useState(false);
  const connectedWalletModalVisibility = useMemo(
    () => isVisibleWalletConnect && user.isConnectedWallet,
    [isVisibleWalletConnect, user.isConnectedWallet],
  );

  const [isMenuMobileOpen, setMenuMobileOpen] = useState<boolean>(false);
  const headerRef = useRef<HTMLElement | null>(null);
  const handleResize = () => {
    setMenuMobileOpen(false);
  };
  useResize(handleResize);
  useBodyScrollLock(headerRef, isMenuMobileOpen);

  useEffect(() => {
    if (!user.isConnectedWallet) {
      setIsVisibleWalletConnect(false);
    }
  }, [user.isConnectedWallet]);

  return (
    <header
      ref={headerRef}
      className={cn(styles.header, {
        [styles.open]: isMenuMobileOpen,
      })}
    >
      <Container className={styles.container}>
        <Modal
          className={styles.walletConnectedModal}
          closeBtnClassName={styles.walletConnectedModalCloseBtn}
          isOpen={connectedWalletModalVisibility}
          defaultStyle={false}
          onClose={() => setIsVisibleWalletConnect(false)}
        >
          <WalletConnected />
        </Modal>
        <Logo
          className={cn(styles.logo, {
            [styles.extended]: isMenuMobileOpen,
          })}
          color={isMenuMobileOpen ? 'primary' : 'white'}
        />
        <HeaderExternalLinks
          className={styles.externalLinks}
          classNameItem={styles.externalLinksItem}
          classNameLink={styles.externalLink}
        />
        <MemoizedLanguageSelect
          className={styles.languageSelect}
          value={OPTIONS_AS_MAP[user.pageLanguage]}
          isDisabled={!isGoogleTranslateInitialized}
          onChange={(option) => {
            if (option) {
              handleChangeLanguage(option);
            }
          }}
        />
        {user.isConnectedWallet ? (
          <button
            type="button"
            className={styles.connectBtn}
            onClick={() => setIsVisibleWalletConnect(true)}
          >
            {shortenedUserAddress}
          </button>
        ) : (
          <Tooltip
            className={styles.showConnectWallet}
            event="click"
            content={<HeaderConnectionMenu />}
          >
            <button type="button" className={styles.connectBtn}>
              Connect wallet
            </button>
          </Tooltip>
        )}

        <Burger
          isActive={isMenuMobileOpen}
          className={styles.burger}
          onClick={() => setMenuMobileOpen(!isMenuMobileOpen)}
        />
      </Container>

      <HeaderMobileMenu
        className={cn(styles.mobileMenu, {
          [styles.hidden]: !isMenuMobileOpen,
        })}
      />
    </header>
  );
});
