import { AbiItem } from 'web3-utils';

import { contracts } from '@/config';
import { walletService } from '@/services';

/**
 * @param contractAbi if undefined -> BEP20
 * @param who if undefined -> userWalletAddress
 * @returns
 */
export const balanceOf = ({
  contractAddress,
  contractAbi,
  who,
}: {
  contractAddress: string;
  contractAbi?: AbiItem[];
  who?: string;
}): Promise<string> => {
  const contract = walletService.connectWallet.getContract({
    address: contractAddress,
    abi: contractAbi || contracts.params.bep20.abi,
  });
  return contract.methods.balanceOf(who || walletService.walletAddress).call();
};
