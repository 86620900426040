import { MutableRefObject, useEffect } from 'react';

export function useClickOutside(
  ref: MutableRefObject<HTMLElement | null> | MutableRefObject<HTMLElement | null>[],
  handler: (e: MouseEvent) => void,
) {
  useEffect(() => {
    const listener = (event: MouseEvent) => {
      if (Array.isArray(ref)) {
        const filteredRefs = ref.filter((_ref) => {
          if (_ref.current) {
            return _ref.current?.contains(event.target as HTMLElement);
          }
          return true;
        });

        if (filteredRefs.length) {
          return;
        }

        handler(event);
      } else {
        if (!ref.current || ref.current.contains(event.target as HTMLElement)) {
          return;
        }
        handler(event);
      }
    };

    document.addEventListener('click', listener);

    return () => {
      document.removeEventListener('click', listener);
    };
  }, [ref, handler]);
}
